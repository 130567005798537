export const EMAIL_STATUSES = {
  confirmation: 'confirmation',
  sending: 'sending',
  success: 'success',
  error: 'error',
};

export const FILTER_OPTIONS = {
  COMPLETE: 'complete',
  INCOMPLETE: 'incomplete',
  NO_ACTIVITY: 'in_progress',
};

export const CONFIRMED_STATUS = 'confirmed';
export const PENDING_STATUS = 'processed';
