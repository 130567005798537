import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import * as PropTypes from 'prop-types';

import PersonIcon from '@mui/icons-material/Person';
import MapIcon from '@mui/icons-material/Map';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import styles from './styles';

const SearchSwitcher = ({ onSwitch }) => {
  const classes = styles();

  const [isOpen, setIsOpen] = useState(false);
  const [isSearchingByAddress, setIsSearchingByAddress] = useState(true);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setIsOpen((state) => !state);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setIsOpen(false);
  };

  const handleSelect = (selectedValue) => (event) => {
    setIsSearchingByAddress(selectedValue);
    onSwitch(selectedValue);
    handleClose(event);
  };

  return (
    <>
      <Box p={0.5}>
        <Button ref={anchorRef} onClick={handleToggle}>
          {isSearchingByAddress ? <MapIcon /> : <PersonIcon />}
          {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Button>
      </Box>
      <Box p={1} pl={0.5} height="40px">
        <Divider orientation="vertical" />
      </Box>
      {isOpen && (
        <Popper open={isOpen} anchorEl={anchorRef.current} placement="bottom-start" transition disablePortal>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    <MenuItem onClick={handleSelect(false)}>
                      <ListItemIcon>
                        <PersonIcon />
                      </ListItemIcon>
                      <Typography>Search by Name</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleSelect(true)}>
                      <ListItemIcon>
                        <MapIcon />
                      </ListItemIcon>
                      <Typography>Search by Address</Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};

SearchSwitcher.propTypes = {
  onSwitch: PropTypes.func.isRequired,
};

export default SearchSwitcher;
