import * as PropTypes from 'prop-types';
import { components } from 'react-select';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseRoundedIcon sx={{ fontSize: 13 }} data-testid="clearOption" />
    </components.MultiValueRemove>
  );
};

MultiValueRemove.propTypes = {
  children: PropTypes.node,
};

export default MultiValueRemove;
