import { useQuery } from '@apollo/client';

import { getServiceProvider } from '@uptime/shared/graphql/marketplace';
import { getAccountProfile } from '@uptime/shared/graphql/users';
import { CONFIRMED_STATUS } from '@uptime/shared/constants/statuses';
import { userClient as client } from '@uptime/shared/graphql/apolloClientConfiguration';
import { marketplaceClient } from '@uptime/controller/src/apolloClientConfiguration';

export default (userId, technicianUserId, operations = {}) => {
  const { shouldSkipAccount = false, shouldSkipSP = false } = operations;
  const {
    loading,
    data: { profile } = {},
    error,
  } = useQuery(getAccountProfile, {
    variables: {
      userId: technicianUserId,
    },
    client,
    skip: shouldSkipAccount,
  });

  const {
    loading: serviceProviderIsLoading,
    data: { serviceProvider } = {},
    error: serviceProviderError,
  } = useQuery(getServiceProvider, {
    variables: {
      userId,
      technicianUserId,
    },
    client: marketplaceClient,
    fetchPolicy: 'no-cache',
    skip: shouldSkipSP,
  });

  return {
    isLoading: loading || serviceProviderIsLoading,
    isError: Boolean(error || serviceProviderError),
    profile,
    isExistingInSPList: serviceProvider && serviceProvider.status === CONFIRMED_STATUS,
  };
};
