import React, { forwardRef, useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { Snackbar, Slide } from '@mui/material';
import { Alert } from '@mui/material';

const SnackMessage = (props) => {
  const { type = 'info', message, onClose } = props;

  const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

  const [isOpenSnack, setIsOpenSnack] = useState(false);

  useEffect(() => {
    message && setIsOpenSnack(true);
    return () => setIsOpenSnack(false);
  }, [message]);

  const handleClose = () => {
    setIsOpenSnack(false);
    onClose && onClose();
  };

  return (
    <Snackbar
      open={isOpenSnack}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Alert onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

SnackMessage.propTypes = {
  type: PropTypes.oneOf(['error', 'success', 'warning', 'info']),
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

SnackMessage.defaultProps = {
  type: 'info',
};

export default SnackMessage;
