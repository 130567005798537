import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import debounce from 'lodash/debounce';

const AsyncPaginate = ({ loadOptions, value, persistSelected, ...rest }) => {
  const [selectedValues, setSelectedValues] = useState(
    rest.isMulti ? persistSelected ?? [] : [persistSelected]
  );
  const [options, setOptions] = useState([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [hasMoreResults, setPaginateBoolean] = useState(true);
  const [asyncLoading, toggleAsyncLoading] = useState(false);

  const loadAsyncOptions = async (s = search, p = 1) => {
    if (!hasMoreResults && p > 1) return;
    toggleAsyncLoading(true);
    const results = await loadOptions(s, p);
    const _options = p > 1 ? [...options, ...results?.options] : results?.options;
    setPaginateBoolean(results?.total > _options.length);
    setPage(p + 1);
    setOptions(_options);
    toggleAsyncLoading(false);
  };

  useEffect(() => {
    const debounceAsyncCall = debounce((value) => loadAsyncOptions(value), 300);
    debounceAsyncCall(search);

    // Cleanup function to cancel debounce on unmount or search change
    return () => {
      debounceAsyncCall.cancel();
    };
  }, [search]);

  const onMenuOpen = () => {
    setPaginateBoolean(true);
    setPage(1);
    setOptions([]);
    loadAsyncOptions(search, 1);
  };

  // Store selected values
  const handleChange = (event) => {
    const values = event && (rest.isMulti ? event.map((e) => e.value) : [event.value]);

    const mergedOptions = [...options, ...selectedValues];
    const uniqueOptions = Array.from(new Map(mergedOptions.map((obj) => [obj.id, obj])).values());

    const filteredObjects = values ? uniqueOptions.filter((obj) => values.includes(obj.id)) : [];

    setSelectedValues(filteredObjects);
    rest.onChange(event);
  };

  const conditionalValue = selectedValues ? selectedValues[0] : value;
  const optionValue = rest.isMulti
    ? selectedValues
    : selectedValues === undefined
      ? undefined
      : conditionalValue;

  return (
    <Select
      {...rest}
      onMenuOpen={onMenuOpen}
      onInputChange={(value) => setSearch(value)}
      loadMoreOptions={debounce((value, p) => loadAsyncOptions(value, p), 300)}
      page={page}
      value={optionValue}
      onChange={handleChange}
      hasMoreResults={hasMoreResults}
      options={options}
      captureMenuScroll={true}
      noOptionsMessage={() => (asyncLoading ? 'Loading results...' : 'No results')}
    />
  );
};

export default AsyncPaginate;
