import makeStyles from '@mui/styles/makeStyles';
import marketBackground from '@uptime/shared/assets/market-background.jpg';

export default makeStyles((theme) => ({
  wrapper: {
    backgroundImage: `linear-gradient(70deg, ${theme.palette.primary.main}CC,  ${theme.palette.primary.dark}CC), url(${marketBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    height: '100%',
    margin: 0,
  },
  container: {
    position: 'absolute',
    backgroundSize: 'cover',
  },
  header: {
    fontWeight: 700,
    fontSize: 32,
    color: theme.palette.grey.white,
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 16,
    color: theme.palette.grey.black,
  },
  subTitle: {
    fontWeight: 400,
    fontSize: 20,
    color: theme.palette.grey.white,
  },
  card: {
    position: 'relative',
    zIndex: 1,
    maxWidth: 544,
    marginTop: theme.spacing(4),
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      height: 428,
    },
  },
  divider: {
    backgroundColor: `${theme.palette.grey[200]} !important`,
  },
  andOrSection: {
    background: theme.palette.common.white,
    color: theme.palette.grey.black,
    boxSizing: 'border-box',
    borderRadius: 4,
    display: 'inline-block',
    padding: theme.spacing(0.5, 1),
    position: 'absolute',
    left: theme.spacing(4.5),
    fontSize: 14,
    top: -14,
  },
  backButton: {
    color: theme.palette.grey.white,
    fontSize: 14,
    padding: theme.spacing(0),
  },
}));
