import React from 'react';
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { getMappedServices } from '@uptime/shared/utils/marketplace';
import ServiceIcon from '@uptime/shared/components/Icons/ServiceIcon';
import styles from '@uptime/shared/styles/TechnicianStyles';
import EmptyPlaceholder from '@uptime/shared/components/Placeholders/EmptyPlaceholder';
import ServiceSkeleton from '@uptime/shared/components/Skeleton/ServiceSkeleton';

import Service from '@uptime/shared/components/TechnicianWizard/Service';

const Pricing = (props) => {
  const classes = styles();
  const { services, isLoading } = props;

  const renderServices = isLoading ? (
    <>
      <ServiceSkeleton />
      <ServiceSkeleton />
      <ServiceSkeleton />
    </>
  ) : (
    <Box
      boxSizing="border-box"
      position="absolute"
      width="100%"
      height="100%"
      overflow="auto"
      top={0}
      left={0}
      p={2}
    >
      {getMappedServices(services).map((service) => (
        <Service service={service} key={service.id} />
      ))}
    </Box>
  );

  const renderContent =
    !isLoading && isEmpty(services) ? (
      <Box display="flex" alignItems="center" height="100%">
        <EmptyPlaceholder>
          <ServiceIcon />
        </EmptyPlaceholder>
      </Box>
    ) : (
      renderServices
    );

  return (
    <Box p={3} flex="1" display="flex">
      <Box flex="1" position="relative" className={classes.card}>
        {renderContent}
      </Box>
    </Box>
  );
};

Pricing.propTypes = {
  services: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Pricing;
