import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import classNames from 'classnames';

import styles from './styles';

const PinIcon = ({ onClick, isHoverEnabled, children, title }) => {
  const classes = styles();
  const pinClasses = classNames(classes.pin, {
    [classes.count]: Boolean(children),
    [classes.hover]: isHoverEnabled,
  });

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      className={classes.root}
    >
      {title && (
        <Box position="absolute" top={-20} left={15} className={classes.title}>
          {title}
        </Box>
      )}
      <div className={classes.shadow} />
      <div className={pinClasses} onClick={onClick}>
        {children}
      </div>
    </Box>
  );
};

PinIcon.propTypes = {
  onClick: PropTypes.func,
  isHoverEnabled: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.node,
};

export default PinIcon;
