import React, { useState } from 'react';
import Card from '@mui/material/Card/Card';
import Typography from '@mui/material/Typography/Typography';
import Button from '@mui/material/Button/Button';
import Box from '@mui/material/Box';
import { Field, Form } from 'react-final-form';
import { useQuery } from '@apollo/client';
import * as PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

import SearchAddress from '@uptime/shared/components/SearchAddress';
import { devicesClient } from '@uptime/shared/graphql/apolloClientConfiguration';
import { getAllEquipmentCategories } from '@uptime/shared/graphql/devices';
import AutoCompleteInput from '@uptime/shared/components/AutoComplete';
import startFormStyles from '@uptime/shared/screens/Marketplace/StartForm/styles';
import { TEXT_VALUES } from '@uptime/shared/constants/device';
import useLocalStorage from '@uptime/shared/hooks/useLocalStorage';
import Divider from '@mui/material/Divider';

import styles from './styles';

const RECAPTCHA_LOCAL_STORAGE = 'isRecaptchaVerified';

const StartForm = (props) => {
  const startFormClasses = startFormStyles();
  const classes = styles();
  const { onSubmit } = props;
  const [isVerified, setIsVerified] = useState(false);

  const { loading: categoriesAreLoading, data: { categories } = {} } = useQuery(getAllEquipmentCategories, {
    variables: {
      userId: null,
    },
    client: devicesClient,
  });

  const [isHideRecaptcha, setIsHideRecaptcha] = useLocalStorage(RECAPTCHA_LOCAL_STORAGE, false);

  const equipmentCategoryPlaceholder = categoriesAreLoading ? TEXT_VALUES.LOADING : 'Choose Category';

  const recaptchaRef = React.createRef();
  const recaptchaLoaded = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      setIsVerified(true);
      setIsHideRecaptcha(true);
    }
  };

  const handleRecaptchaError = () => {
    setIsVerified(false);
  };

  return (
    <Card className={classes.form}>
      <Box className={classes.titleWrapper}>
        <Typography className={classes.title} variant="h3">
          Enter the category of equipment you would like serviced and/or the city you need them. You can
          always change this later.
        </Typography>
      </Box>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, form: { change } }) => {
          const handleSelectAddress = (locationContext, locationViewPort) => {
            const { longitude, latitude } = locationViewPort;
            const { label } = locationContext;
            const coordinates = {
              x: longitude,
              y: latitude,
            };

            change('city', label);
            change('location', {
              coordinates,
              range: 5,
            });
          };

          return (
            <form onSubmit={handleSubmit}>
              <Field type="hidden" name="location" component="input" />
              <Box mb={5} pl={2} pr={2}>
                <Field
                  name="equipmentCategoryId"
                  label="Category"
                  placeholder={equipmentCategoryPlaceholder}
                  component={AutoCompleteInput}
                  options={categories}
                  isDisabled={categoriesAreLoading}
                  isClearable
                />
              </Box>
              <Box position="relative" mt={3} mb={3}>
                <Divider className={startFormClasses.divider} />
                <Box className={startFormClasses.andOrSection}>and/or</Box>
              </Box>
              <Box pl={2} mt={5} pr={2} pb={2}>
                <SearchAddress
                  placeholder="Choose Facility"
                  name="city"
                  label="Facility"
                  onSelectAddress={handleSelectAddress}
                  params={{
                    types: 'place',
                    country: 'us',
                  }}
                />
              </Box>
              {!isHideRecaptcha && !isVerified && (
                <Box pl={3} pr={3}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LfmAzYaAAAAAGbAXttF6wzJ7K6sdG-jaweEvZLY"
                    render="explicit"
                    onChange={recaptchaLoaded}
                    onErrored={handleRecaptchaError}
                    onExpired={handleRecaptchaError}
                  />
                </Box>
              )}
              <Box display="flex" flexDirection="column" alignItems="stretch" pl={4} pr={4} pb={3} mt={2}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  size="large"
                  disabled={!(isVerified || isHideRecaptcha)}
                  sx={{ height: 40, fontSize: 14 }}
                >
                  Search
                </Button>
              </Box>
            </form>
          );
        }}
      />
    </Card>
  );
};

StartForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default StartForm;
