import { ACTION_TYPES } from '../constants';

export const setPolygon = (polygon) => (dispatch) =>
  dispatch({
    type: ACTION_TYPES.SET_POLYGON,
    payload: { polygon },
  });

export const setAddressOptions = (addressOptions) => (dispatch) =>
  dispatch({
    type: ACTION_TYPES.SET_ADDRESS_OPTIONS,
    payload: { addressOptions },
  });

export const setSearch = (search) => (dispatch) =>
  dispatch({
    type: ACTION_TYPES.SET_SEARCH,
    payload: { search },
  });

export const setSearchTimer = (searchTimer) => (dispatch) =>
  dispatch({
    type: ACTION_TYPES.SET_SEARCH_TIMER,
    payload: { searchTimer },
  });

export const setRequestTimer = (requestTimer) => (dispatch) =>
  dispatch({
    type: ACTION_TYPES.SET_REQUEST_TIMER,
    payload: { requestTimer },
  });

export const setSearchingByAddress = (isSearchingByAddress) => (dispatch) =>
  dispatch({
    type: ACTION_TYPES.SET_SEARCHING_BY_ADDRESS,
    payload: { isSearchingByAddress },
  });

export const setLoading = (isLoading) => (dispatch) =>
  dispatch({
    type: ACTION_TYPES.SET_LOADING,
    payload: { isLoading },
  });
