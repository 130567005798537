import { styled } from '@mui/system';
import { Slider as MuiSlider } from '@mui/material';

const Slider = styled(MuiSlider)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: 8,
  '& .MuiSlider-thumb': {
    height: 15,
    width: 15,
    backgroundColor: theme.palette.primary.main,
    border: '4px solid currentColor',
    marginTop: 0,
    marginLeft: -4,
    '&:after': {
      background: theme.palette.primary.main,
      opacity: '.2',
    },
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
      top: 16,
      left: -14,
      bottom: -14,
      right: -14,
    },
  },
  '& .MuiSlider-track': {
    height: 2,
    borderRadius: 4,
  },
  '& .MuiSlider-rail': {
    height: 2,
    borderRadius: 4,
  },
  '& .MuiSlider-valueLabel': {
    backgroundColor: theme.palette.grey[700],
    opacity: 0.7,
    borderRadius: 4,
    transform: 'translateY(115%) scale(1) !important',
    '&:before': {
      top: 0,
      transform: 'translate(-50%, -50%) rotate(45deg)',
    },
  },
}));

export default Slider;
