import React from 'react';
import Card from '@mui/material/Card/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography/Typography';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';

import styles from '@uptime/shared/styles/TechnicianStyles';

export default () => {
  const classes = styles();

  return (
    <Box mb={2}>
      <Card className={classes.item}>
        <Box className={classes.colorDivider} />
        <Box p={2}>
          <Grid container>
            <Grid item xs={12} sm={6} lg={7}>
              <Box height="100%" display="flex" alignItems="center">
                <Skeleton variant="text" width={120} height={36} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={5}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography>
                  <Skeleton variant="text" width={120} height={36} />
                </Typography>
                <Box height={24} pl={2} pr={2}>
                  <Divider orientation="vertical" />
                </Box>
                <Typography className={classes.rate}>
                  <Skeleton variant="text" width={100} height={36} />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box pt={2} pb={2}>
                <Divider />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="flex-end">
                <Box pr={2}>
                  <Skeleton variant="text" width={100} height={28} />
                </Box>
                <Skeleton variant="text" width="100%" height={36} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};
