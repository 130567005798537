import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    maxWidth: `calc(100% + ${theme.spacing(8)})`,
    boxSizing: 'border-box',
    '& .MuiTypography-root': {
      textAlign: 'left',
    },
    '& > div': {
      margin: 0,
      padding: theme.spacing(2),
      maxWidth: '100%',
      boxSizing: 'border-box',
    },
    '& > div > div > .MuiBox-root': {
      padding: theme.spacing(0, 2),
      display: 'flex',
    },
    '& .mapboxgl-map': {
      borderRadius: 6,
    },
  },
  filters: {
    [theme.breakpoints.down('lg')]: {
      '& .MuiGrid-item > .MuiBox-root ': {
        marginBottom: theme.spacing(0.5),
        marginTop: theme.spacing(0.5),
      },
    },
  },
  toggleMap: {
    margin: `${theme.spacing(5)} auto auto`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiToggleButtonGroup-grouped:nth-child(2)': {
      margin: `0 ${theme.spacing(4)} 0 0`,
    },
    '& .MuiButtonBase-root': {
      fontWeight: 400,
    },
  },
}));
