import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { getProfileFileUrl } from '@uptime/shared/graphql/documents';
import UserAvatar from '@uptime/shared/components/UserAvatar';
import { filesClient as client } from '@uptime/controller/src/apolloClientConfiguration';

const TechnicianAvatar = ({ userId, userName, onLoaded, isLoaded, ...other }) => {
  const { loading, data: { url } = {} } = useQuery(getProfileFileUrl, {
    variables: {
      userId,
    },
    client,
    skip: isLoaded,
  });

  return <UserAvatar isLoading={!isLoaded && loading} src={url} name={userName} {...other} />;
};

TechnicianAvatar.propTypes = {
  userId: PropTypes.number.isRequired,
  userName: PropTypes.string.isRequired,
  onLoaded: PropTypes.func,
  isLoaded: PropTypes.bool,
};

export default memo(TechnicianAvatar, (prevProps, nextProps) => {
  const { userId: prevUserId } = prevProps;
  const { userId: nextUserId } = nextProps;

  return prevUserId === nextUserId;
});
