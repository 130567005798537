import pick from 'lodash/pick';
import isArray from 'lodash/isArray';
import isDate from 'lodash/isDate';
import isObject from 'lodash/isObject';
import uniq from 'lodash/uniq';
import max from 'lodash/max';
import min from 'lodash/min';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

import { ALL_MAKES_VALUE } from '@uptime/shared/constants/account';
import { currencyFormatter, parseMilliseconds } from '@uptime/shared/utils/general';
import { timeFormat } from '@uptime/shared/utils/date';
import { EMPTY, FULL_DAY } from '@uptime/shared/components/constants';

export const getSchedule = (service = {}) => {
  const { days, fromHours, toHours } = service;

  if (!isArray(days)) {
    return {};
  }

  return days.reduce(
    (acc, day) => ({
      ...acc,
      [day]: {
        from: parseInt((isDate(fromHours) ? fromHours.getTime() : fromHours) / 1000),
        to: parseInt((isDate(toHours) ? toHours.getTime() : toHours) / 1000),
      },
    }),
    {}
  );
};

export const getServicesData = (services = []) =>
  services
    .filter(({ id }) => !id)
    .map((service) => {
      const pickedService = pick(service, ['typeId', 'rate', 'color', 'description']);

      const schedule = getSchedule(service);

      return {
        ...pickedService,
        schedule,
      };
    });

export const getCoveragesData = (coverages = []) =>
  coverages
    .filter(({ id }) => !id)
    .map((coverage) => {
      const { city, distance, longitude, latitude, isNationwide } = coverage;

      return {
        title: city,
        range: distance,
        nationwide: Boolean(isNationwide),
        coordinates: {
          x: longitude,
          y: latitude,
        },
      };
    });

export const getEquipmentsData = (equipments = []) =>
  equipments
    .filter(({ id }) => !id)
    .map(({ equipmentMakeId, ...equipment }) => {
      return {
        ...pick(equipment, ['equipmentCategoryId', 'equipmentCategoryTitle', 'equipmentMakeTitle']),
        equipmentMakeId: equipmentMakeId === ALL_MAKES_VALUE ? null : equipmentMakeId,
        isCertified: equipment.isCertified || false,
      };
    });

export const getMappedServices = (services = []) =>
  services.map(({ schedule, ...other }) => {
    const days = Object.keys(schedule).reduce(
      ({ days, fromHours, toHours }, day) => {
        if (isObject(schedule[day])) {
          return {
            days: [...days, day],
            fromHours: parseMilliseconds(schedule[day].from),
            toHours: parseMilliseconds(schedule[day].to),
          };
        }

        return { days, fromHours, toHours };
      },
      {
        days: [],
        fromHours: null,
        toHours: null,
      }
    );

    return {
      ...other,
      ...days,
    };
  });

export const getMergedTechnicianResponse = (data = [], saved = []) => [
  ...data.filter(({ id }) => id),
  ...saved,
];

export const getMappedCoverages = (coverages = []) =>
  coverages.map(({ coordinates, ...other }) => ({ ...other, ...coordinates }));

export const getRateRangeString = (rateRange = []) => {
  const rates = uniq(rateRange);

  return rates.map((r) => currencyFormatter(r)).join(' - ') || currencyFormatter(0);
};

export const getAttributesCount = (obj = {}) => {
  const count = Object.values(obj).filter((item) => item !== null).length;
  const additional = obj.location ? 1 : 0;

  return count + additional;
};

export const findCoverage = (coverages = [], values = {}) =>
  coverages.find(({ longitude, latitude }) => latitude === values.latitude && longitude === values.longitude);

export const getCertifiedStatus = (equipments = []) =>
  equipments.filter(({ isCertified }) => isCertified).length > 0;

export const normalizeTimes = (times = {}) => {
  const { from, to } = times;

  if (!from && !to) return {};

  const fromDate = new Date(parseMilliseconds(from));
  const toDate = new Date(parseMilliseconds(to));

  const fromInMilliseconds = new Date().setHours(fromDate.getHours(), fromDate.getMinutes(), 0, 0);
  const timeFrom = timeFormat(fromInMilliseconds);

  const toInMilliseconds = new Date().setHours(toDate.getHours(), toDate.getMinutes(), 0, 0);
  const timeTo = timeFormat(toInMilliseconds);

  return {
    fromInMilliseconds,
    toInMilliseconds,
    timeFrom,
    timeTo,
  };
};

export const getServiceSlots = (timeSlot, serviceData) => {
  if (!(timeSlot && serviceData)) return [];

  const { fromInMilliseconds, toInMilliseconds, timeFrom, timeTo } = timeSlot;

  return fromInMilliseconds <= toInMilliseconds
    ? [
        {
          ...serviceData,
          caption: fromInMilliseconds === toInMilliseconds ? FULL_DAY : `${timeFrom} - ${timeTo}`,
        },
      ]
    : [
        {
          ...serviceData,
          time: startOfDay(new Date(fromInMilliseconds)).getTime(),
          caption: `12:00 AM - ${timeTo}`,
        },
        {
          ...serviceData,
          caption: `${timeFrom} - 12:00 AM`,
        },
      ];
};

export const getServiceTimes = ({ fromInMilliseconds, toInMilliseconds } = {}) => {
  if (!(fromInMilliseconds && toInMilliseconds)) return [];

  return fromInMilliseconds < toInMilliseconds
    ? [fromInMilliseconds, toInMilliseconds]
    : [
        startOfDay(new Date(fromInMilliseconds)).getTime(),
        endOfDay(new Date(fromInMilliseconds)).getTime() + 1,
      ];
};

export const getTimeSlots = (services = [], day) =>
  services.reduce(
    (accumulator, { type: { title: label }, schedule, color }) => {
      const timeSlot = schedule[day];

      if (timeSlot) {
        const { times, slots } = accumulator;

        const normalizedTimes = normalizeTimes(timeSlot);

        const { fromInMilliseconds } = normalizedTimes;

        const serviceData = {
          color,
          label,
          time: fromInMilliseconds,
        };

        const serviceSlots = getServiceSlots(normalizedTimes, serviceData);

        const serviceTimes = getServiceTimes(normalizedTimes);

        return {
          times: [...times, ...serviceTimes],
          slots: [...slots, ...serviceSlots],
        };
      }

      return accumulator;
    },
    { times: [], slots: [] }
  );

export const getDayChart = (services = [], day) => {
  const { times, slots: notSortedSlots } = getTimeSlots(services, day);

  const slots = notSortedSlots.sort(function ({ time: a }, { time: b }) {
    return a - b;
  });

  if (times.length === 0)
    return {
      caption: EMPTY,
      slots,
    };

  const maxTime = max(times);
  const minTime = min(times);
  const fromHours = timeFormat(minTime);
  const toHours = timeFormat(maxTime);

  return {
    caption: fromHours === toHours ? FULL_DAY : `${fromHours} - ${toHours}`,
    slots,
  };
};

export const getEquipmentInitialValues = (values) => {
  if (!values) return values;

  const { equipments } = values;
  const mappedEquipments = equipments.map(({ equipmentMakeId, ...other }) => ({
    ...other,
    equipmentMakeId: equipmentMakeId === null ? ALL_MAKES_VALUE : equipmentMakeId,
  }));

  return {
    ...values,
    equipments: mappedEquipments,
  };
};

export const getTechnicianFullName = (technician) => {
  if (!technician) return null;

  return `${technician.firstName} ${technician.lastName}`;
};

export const getTechnicianEmail = (technician) => {
  if (!technician) return null;

  return technician.email;
};

export const getTechnicianBusinessName = (technician) => {
  if (!technician) return null;

  return technician.businessName;
};

export const getTechnicianOptions = (hits = []) => {
  return hits.map((serviceProvider) => {
    const { id, technicianUserId, technician, spFacilities, spCategories, supportType } = serviceProvider;
    const fullName = getTechnicianFullName(technician);
    const email = getTechnicianEmail(technician);
    const businessName = getTechnicianBusinessName(technician);
    const facilityFacilities = spFacilities?.map((item) => item.facilityId);
    const deviceCategories = spCategories?.map((item) => item.equipmentCategoryId);
    const deviceFacilities = spCategories?.map((item) => item.facilityId);

    return {
      value: id,
      label: fullName,
      technicianUserId,
      email,
      supportType,
      businessName,
      facilityFacilities,
      deviceCategories,
      deviceFacilities,
    };
  });
};

export const getTechnicianById = (hits = [], technicianId) => {
  const result = hits.find((item) => item.technicianUserId === technicianId);
  return Boolean(result) ? result : undefined;
};
