import React from 'react';
import Card from '@mui/material/Card/Card';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton/IconButton';
import * as PropTypes from 'prop-types';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';

import styles from '@uptime/shared/styles/TechnicianStyles';
import { timeFormat } from '@uptime/shared/utils/date';
import { getDaysLabel } from '@uptime/shared/utils/account';
import { currencyFormatter } from '../../utils/general';

const Service = (props) => {
  const classes = styles();

  const {
    service: {
      color: backgroundColor,
      typeId,
      rate,
      days,
      fromHours,
      toHours,
      description,
      type: { title } = {},
    } = {},
    serviceTypes = [],
    onDelete,
    onEdit,
  } = props;

  const fromFormattedHour = timeFormat(fromHours);
  const toFormattedHour = timeFormat(toHours);
  const daysLabel = getDaysLabel(days);
  const { label } = serviceTypes.find(({ value }) => value === typeId) || {};

  return (
    <Box mb={2}>
      <Card className={classes.item}>
        <Box className={classes.colorDivider} style={{ backgroundColor }} />
        <Box p={2}>
          <Grid container>
            <Grid item xs={12} sm={6} lg={7}>
              <Box height="100%" display="flex" alignItems="center">
                <Typography>
                  <strong>{label || title}</strong>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={5}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography>
                  {daysLabel} {fromFormattedHour} - {toFormattedHour}
                </Typography>
                <Box height={24} pl={2} pr={2}>
                  <Divider orientation="vertical" />
                </Box>
                <Typography className={classes.rate}>
                  Rate <span>{currencyFormatter(rate)}</span>/hour
                </Typography>
                {onEdit && (
                  <Tooltip className={classes.serviceIcon} title="Edit" arrow>
                    <IconButton onClick={onEdit} size="large">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {onDelete && (
                  <Tooltip title="Delete" className={classes.serviceIcon} arrow>
                    <IconButton onClick={onDelete} size="large">
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box pt={2} pb={2}>
                <Divider />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="baseline">
                <Box pr={2}>
                  <Typography className={classes.label}>Description</Typography>
                </Box>
                <Typography variant="subtitle2">{description}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
};

Service.propTypes = {
  service: PropTypes.object,
  serviceTypes: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default Service;
