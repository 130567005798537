import React from 'react';
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';

const Info = ({ photo, view, actions }) => (
  <Box display="flex" pb={1}>
    {photo && (
      <Box mt={1} ml={1}>
        {photo}
      </Box>
    )}
    <Box width="100%">{view}</Box>
    <Box mr={-1}>{actions}</Box>
  </Box>
);

Info.propTypes = {
  photo: PropTypes.node,
  actions: PropTypes.node.isRequired,
  view: PropTypes.node,
};

export default Info;
