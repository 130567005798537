import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  buttonContainer: {
    marginRight: theme.spacing(3),
    '& .MuiButtonBase-root': {
      border: `1px solid ${theme.palette.primary.light1}`,
      padding: theme.spacing(1),
    },
    '& .MuiButtonBase-root:first-child': {
      borderRadius: '4px 0 0 4px',
    },
    '& .MuiButtonBase-root:last-child': {
      borderRadius: '0px 4px 4px 0',
    },
    '& .MuiToggleButton-root.Mui-selected': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
  },
  toggleAction: {
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light2,
    },
  },
}));
