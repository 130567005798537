import React from 'react';
import { Typography } from '@mui/material';
import * as PropTypes from 'prop-types';

const ValueContainer = (props) => {
  const { hasValue, isMulti, children } = props;
  const newChildren = children[0]?.length && children[0].filter((item) => item?.props?.data?.value !== -1);
  const childrenToRender = newChildren
    ? [
        React.Children.map(newChildren, (item) => (
          <div data-testid={`dropDownValue_${item?.props?.data?.label}`}>{item}</div>
        )),
        children[1],
      ]
    : children;

  return (
    <div className={props.selectProps.classes.valueContainer}>
      {childrenToRender}
      {isMulti && hasValue && (
        <Typography variant="caption" className={props.selectProps.classes.valueCount}>
          {childrenToRender[0]?.length}
        </Typography>
      )}
    </div>
  );
};

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
  hasValue: PropTypes.bool.isRequired,
};

export default ValueContainer;
