import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  yellowIcon: {
    color: theme.palette.warning.main,
  },
  darkTitle: {
    color: theme.palette.grey.black,
    fontSize: 16,
    fontWeight: 500,
  },
  textData: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    '& .MuiListItemText-primary': {
      color: theme.palette.grey.black,
      fontSize: 15,
      order: 1,
      wordBreak: 'break-word',
    },
    '& .MuiListItemText-secondary': {
      color: theme.palette.grey[500],
      fontSize: 12,
      order: 0,
      [theme.breakpoints.up('md')]: {
        whiteSpace: 'nowrap',
      },
    },
  },
  textTruncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  bigAvatar: {
    margin: theme.spacing(2),
    width: '128px !important',
    height: '128px !important',
  },
  stripeButton: {
    '&.Mui-disabled': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: `1px solid ${theme.palette.grey[700]}`,
      '&.Connected': {
        border: `1px solid ${theme.palette.success.main}`,
        color: theme.palette.success.main,
      },
    },
    position: 'relative',
    paddingRight: '1.5rem',
  },
  stripeIcon: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 30,
    lineHeight: '50px',
    width: 50,
    height: 50,
    borderRadius: '50%',
  },
  userItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(1),
    '& .MuiListItemText-root': {
      marginRight: 0,
    },
    '& .MuiAvatar-root': {
      width: 98,
      height: 98,
      marginBottom: theme.spacing(1),
    },
    '& .MuiListItemText-primary': {
      order: 0,
    },
    '& .MuiListItemText-secondary': {
      order: 1,
    },
  },
  saveButton: {
    padding: '.5rem .75rem',
    '& .MuiButton-label': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'block',
    },
  },
  notificationsList: {
    '& .MuiListItem-root': {
      paddingTop: 0,
      paddingBottom: 0,
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
  },
  notificationsTitle: {
    padding: theme.spacing(2),
    minWidth: 180,
  },
  docTypeTitle: {
    minWidth: 120,
    fontSize: 14,
    textTransform: 'capitalize',
  },
  docTypeCode: {
    minWidth: 110,
    fontSize: 14,
  },
}));
