import React from 'react';
import * as PropTypes from 'prop-types';
import { FormControl, InputLabel, Box } from '@mui/material';
import classNames from 'classnames';

import { useStyles } from '@uptime/shared/styles/FormElementStyles';
import Slider from './StyledSlider';

const SliderInput = (props) => {
  const {
    input: { onChange, value },
    label,
    meta = {},
    onChangeCustom,
    formControlClass,
    ...other
  } = props;

  const classes = useStyles();
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  const handleChanging = (event, newValue) => onChange(newValue || null);
  const handleChangingCommitted = (event, newValue) => {
    onChange(newValue || null);
    onChangeCustom && onChangeCustom(event);
  };

  const errorClasses = classNames({
    [classes.error]: true,
    error: true,
  });

  return (
    <Box m={2}>
      <FormControl variant="filled" className={formControlClass} fullWidth>
        {label && (
          <InputLabel shrink error={showError} className={classes.label}>
            {label}
          </InputLabel>
        )}

        <Box pt={3.5}>
          <Slider
            valueLabelDisplay="on"
            onChange={handleChanging}
            onChangeCommitted={handleChangingCommitted}
            value={value || other.min || 0}
            {...other}
          />
        </Box>

        {showError && <div className={errorClasses}>{meta.error || meta.submitError}</div>}
      </FormControl>
    </Box>
  );
};

SliderInput.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChangeCustom: PropTypes.func,
  formControlClass: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
    touched: PropTypes.bool,
  }),
};

SliderInput.defaultProps = {
  label: '',
  disabled: false,
};

export default SliderInput;
