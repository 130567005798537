import styled from 'styled-components';
import makeStyles from '@mui/styles/makeStyles';

import theme from '../../theme/muiTheme';

export const StyledContainer = styled.div`
  position: relative;
  height: ${(props) => `${props.wh}px`};
  width: ${(props) => `${props.wh}px`};
  cursor: pointer;
  padding: 1px;
`;

export const StyledPercentage = styled.span`
  position: absolute;
  width: 100%;
  height: ${(props) => (props.size === 'medium' ? '96%' : '99%')};
  font-size: ${(props) => (props.size === 'medium' ? '16px' : '12px')};
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.isActive ? props.theme.palette.primary.main : theme.palette.grey.black)};
`;

export const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  top: {
    color: `${theme.palette.primary.light1} !important`,
  },
  bottom: {
    color: `${theme.palette.primary.main} !important`,
    animationDuration: '550ms',
    position: 'absolute',
    left: 0,
    top: 0,
  },
}));

export const BarberPoleContainer = styled.div`
  width: 100%;
  min-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MessageContainer = styled.p`
  margin: 3px;
  font-size: 14px;
`;

export const StyledBarberPole = styled.div`
  height: 20px;
  width: 80%;
  margin: auto;

  border-radius: 10px;
  border: 1px solid ${theme.palette.grey[200]};
  background-color: ${theme.palette.grey[100]};

  background-image: repeating-linear-gradient(
    -45deg,
    transparent,
    transparent 1rem,
    ${theme.palette.grey[200]} 1rem,
    ${theme.palette.grey[200]} 2rem
  );
  background-size: 200% 200%;
  animation: barberpole 6s linear infinite;

  @keyframes barberpole {
    100% {
      background-position: 100% 100%;
    }
  }
`;
