import { gql } from '@apollo/client';

export const getAllEquipmentCategories = gql`
  query getAllEquipmentCategories($userId: Int) {
    categories: getAllEquipmentCategories(userId: $userId) {
      value: id
      label: name
    }
  }
`;

export const getDeviceOptions = gql`
  query getDevices($userId: Int!, $filter: FilterSettingsInput! = {}) {
    devices: fetchDevices(userId: $userId, filter: $filter) {
      hits {
        value: id
        label: assetId
        makeTitle: make
        modelTitle: model
        deviceUse
      }
    }
  }
`;

export const getDeviceFileUrl = gql`
  query fetchDeviceFileUrl($deviceId: Int!, $fileId: String!) {
    url: fetchDeviceFileUrl(deviceId: $deviceId, fileId: $fileId)
  }
`;

export const getReplacementAnalysis = gql`
  query getReplacementAnalysis($deviceId: Int!) {
    getReplacementAnalysis(deviceId: $deviceId) {
      deviceAge
      tradeInValue
      serviceCost
      bookValue
      purchaseCost
      lastYearCost
      lastFiveYearsCost
      estimatedNextYear
      estimatedNextFiveYears
      recommendation
    }
  }
`;
