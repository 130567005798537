import React from 'react';
import Button from '@mui/material/Button/Button';
import Box from '@mui/material/Box';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import styles from './styles';

const CustomButton = (props) => {
  const classes = styles();
  const { icon, children, onClick, variant = 'default', position = 'left', ...otherProps } = props;

  const buttonClasses = classNames(classes.button, classes[variant]);
  const textClass = icon ? 'text' : 'rounded';

  return (
    <Button className={buttonClasses} onClick={onClick} {...otherProps}>
      <Box display="flex">
        {icon && position === 'left' && <Box className="icon">{icon}</Box>}
        <Box className={textClass}>{children}</Box>
        {icon && position === 'right' && <Box className="icon">{icon}</Box>}
      </Box>
    </Button>
  );
};

CustomButton.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]).isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  position: PropTypes.string,
};

export default CustomButton;
