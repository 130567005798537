import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';

const ClearIndicator = ({ innerProps }) => {
  const theme = useTheme();

  return (
    <IconButton
      aria-label="Clear AutoComplete"
      edge="end"
      {...innerProps}
      size="large"
      data-testid="clearOption"
    >
      <CloseIcon fontSize="small" style={{ color: theme.palette.grey[600] }} />
    </IconButton>
  );
};

ClearIndicator.propTypes = {
  innerProps: PropTypes.object,
};

export default ClearIndicator;
