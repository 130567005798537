import React, { useEffect, useRef, Fragment, useState } from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import styles from './styles';

const DropDown = (props) => {
  const { onOptionClick, items = [], dropDownStyles = {} } = props;

  const dropDownRef = useRef();
  const [isOpen, setIsOpen] = useState(items.length > 0);

  const classes = styles();

  const dropDownClass = classNames({
    [classes.dropDownContent]: true,
    customDropDown: true,
  });

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleItemClick = (value) => {
    onOptionClick(value);
    setIsOpen(false);
  };

  useEffect(() => {
    items.length > 0 ? setIsOpen(true) : setIsOpen(false);
  }, [items]);

  useEffect(() => {
    const { current } = dropDownRef;

    if (current) {
      document.body.addEventListener('click', handleClickOutside, false);
    }

    return () => {
      if (current) {
        document.body.removeEventListener('click', handleClickOutside, false);
      }
    };
  });

  return (
    <Fragment>
      {isOpen && (
        <div ref={dropDownRef} className={dropDownClass} style={dropDownStyles}>
          {items.map((item, index) => (
            <div onClick={() => handleItemClick(item.value)} key={index}>
              {item.label}
            </div>
          ))}
        </div>
      )}
    </Fragment>
  );
};

DropDown.propTypes = {
  onOptionClick: PropTypes.func.isRequired,
  items: PropTypes.array,
  dropDownStyles: PropTypes.object,
};

export default DropDown;
