import React from 'react';
import { Box, Typography } from '@mui/material';
import * as PropTypes from 'prop-types';

import styles from '@uptime/shared/styles/TechnicianStyles';

const EmptyPlaceholder = ({ children }) => {
  const classes = styles();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" pt={5} pb={5} className={classes.card}>
      <Box className={classes.icon}>{children}</Box>
      <Box pt={2} textAlign="center">
        <Typography>Empty</Typography>
      </Box>
    </Box>
  );
};

EmptyPlaceholder.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EmptyPlaceholder;
