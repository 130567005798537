import makeStyles from '@mui/styles/makeStyles';
import styled from 'styled-components';
import theme from '../../theme/muiTheme';

export const ProgressContainer = styled.div`
  width: 100%;
  height: 4px;
  top: 0;
  background-color: ${theme.palette.common.white};
`;

export default makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(-4),
    marginRight: theme.spacing(-4),
    marginBottom: theme.spacing(-4),
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      marginBottom: theme.spacing(-2),
    },
    paddingTop: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  controls: {
    '& > a': {
      margin: theme.spacing(1),
      lineHeight: '24px',
    },
  },
  noSpace: {
    padding: '0 !important',
  },
}));
