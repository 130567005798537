import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { getParamsFromSearch, toEncodeURI } from '@uptime/shared/utils/general';
import useContainerWidth from '@uptime/shared/hooks/useContainerWidth';

const defaultMutate = (params) => params;

// defaultParams - url params structure, not gql structure
export default function useListDataChanging({
  fetch,
  mutateParams = defaultMutate,
  defaultFilters = {},
  defaultParams,
}) {
  const location = useLocation();
  const history = useHistory();

  const [containerRef, filterContainerWidth] = useContainerWidth();

  const initState = {
    filters: defaultFilters,
    search: null,
    sort: {},
    ...defaultParams,
  };

  const [state, setState] = useState(initState);

  useEffect(() => {
    if (location.search) {
      try {
        const params = getParamsFromSearch(location.search);
        fetch && fetch(mutateParams(params));
        setState(params);
      } catch (e) {
        setState(initState);
      }
    } else {
      fetch && fetch(mutateParams(initState));
    }
    return () => setState(initState);
  }, [location.search]); // eslint-disable-line

  /**
   * Returns an object containing event handlers for changing the list data.
   * @param {Function} toggle - The function that toggles the filter container.
   * @returns {Object} An object containing event handlers.
   */
  const eventHandlers = useCallback(
    (toggle = () => null) => {
      const updateURLParams = (queryParams) => {
        history.push({
          ...location,
          search: toEncodeURI(queryParams),
        });
      };

      return {
        onChangePage: (event, page) => updateURLParams({ ...state, page: page + 1 }),
        onSearch: (event) => updateURLParams({ ...state, ...event, page: 1 }),
        onClearSearch: () => updateURLParams({ ...state, search: null }),
        onReset: () =>
          toggle() || updateURLParams({ ...state, search: null, page: 1, filters: defaultFilters }),
        onSort: (event) => updateURLParams({ ...state, sort: event }),
        onFilter: (event) => toggle() || updateURLParams({ ...state, filters: event, page: 1 }),
      };
    },
    [defaultFilters, state, history, location]
  );

  return [state, eventHandlers, containerRef, filterContainerWidth];
}
