import MapIcon from '@mui/icons-material/Map';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import useStyles from './styles';

type Props = {
  isOpenMap: boolean;
  onChange: (b: boolean) => void;
  shouldRender: boolean;
};

const ToggleMapButtons = ({ isOpenMap, shouldRender = true, onChange }: Props) => {
  const classes = useStyles();

  const handleSelection = (_: any, newSelection: string) => {
    if (newSelection === 'map') {
      onChange(true);
    } else {
      onChange(false);
    }
  };

  if (!shouldRender) return null;

  return (
    <ToggleButtonGroup
      value={isOpenMap ? 'map' : 'list'}
      exclusive
      onChange={handleSelection}
      className={classes.buttonContainer}
    >
      <ToggleButton className={classes.toggleAction} value="list">
        <Tooltip title="List View" arrow>
          <FormatListBulletedIcon />
        </Tooltip>
      </ToggleButton>
      <ToggleButton className={classes.toggleAction} value="map">
        <Tooltip title="Map View" arrow>
          <MapIcon />
        </Tooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleMapButtons;
