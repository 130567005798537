import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 6,
    width: '100%',
    '& > .MuiBox-root': {
      marginTop: 0,
    },
  },
  button: {
    width: '100%',
    padding: theme.spacing(2),
    background: theme.palette.common.white,
    borderRadius: 4,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.12)',
    '&:hover': {
      background: theme.palette.common.white,
      '& .service-add-tier': {
        color: theme.palette.primary.dark,
      },
      '& .service-add-tier-text': {
        textDecoration: 'underline',
        textDecorationColor: theme.palette.primary.dark,
      },
    },
  },
  buttonBlock: {
    width: '100%',
    border: `1px dashed ${theme.palette.grey[300]}`,
    boxSizing: 'border-box',
    borderRadius: 2,
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.main,
  },
  buttonEquipment: {
    width: '100%',
    border: `1px dashed ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.secondary.light,
    boxSizing: 'border-box',
    borderRadius: 2,
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.secondary.dark,
  },
  addTier: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  equipmentForm: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 2,
    '& .MuiInputBase-input': {
      backgroundColor: `${theme.palette.common.white} !important`,
    },
  },
  rate: {
    color: theme.palette.grey[400],
    '& > span': {
      color: theme.palette.grey.black,
    },
  },
  label: {
    color: theme.palette.grey[500],
  },
  item: {
    position: 'relative',
    overflow: 'visible !important',
    '& textarea[name="description"]': {
      height: 87,
    },
  },
  colorDivider: {
    position: 'absolute',
    width: 4,
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.grey[300],
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },
  rateField: {
    '&:after': {
      content: '"per hour"',
      display: 'block',
      whiteSpace: 'nowrap',
      paddingLeft: theme.spacing(1),
      marginLeft: theme.spacing(1),
      borderLeft: `1px solid ${theme.palette.grey[700]}`,
      color: theme.palette.grey[700],
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  icon: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    width: 80,
    height: 80,
    display: 'flex',
    border: `1px solid ${theme.palette.grey[300]}`,
    '& svg': {
      margin: 'auto',
      fontSize: 50,
      color: theme.palette.error.main,
    },
  },
  equipment: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 23,
    '& svg': {
      height: 30,
      width: 30,
    },
    '& .equipmentLabel': {
      color: theme.palette.grey[500],
    },
  },
  serviceIcon: {
    borderRadius: '10%',
    '&:hover': {
      backgroundColor: theme.palette.primary.light2,
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
      },
    },
  },
}));
