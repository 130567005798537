import React, { useState } from 'react';
import { IconButton, ListItemText, Menu, MenuItem, Box, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PropTypes from 'prop-types';

import WrapLink from '../../../components/WrapLink';
import styles from './styles';

const Actions = (props) => {
  const classes = styles();
  const { linkParams, renderWithoutDropdown = false } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const dropDownDisplay = (
    <>
      <Tooltip title="More" arrow>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="large"
          className={classes.toggleAction}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 40 * 4.5,
            width: 200,
          },
        }}
      >
        <MenuItem component={WrapLink} to={linkParams}>
          <ListItemText primary="View" />
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <Box mr={1}>
      <>
        {renderWithoutDropdown ? (
          <IconButton variant="contained" component={WrapLink} to={linkParams} className={classes.viewButton}>
            <Tooltip title="view" arrow>
              <VisibilityIcon />
            </Tooltip>
          </IconButton>
        ) : (
          dropDownDisplay
        )}
      </>
    </Box>
  );
};

Actions.propTypes = {
  id: PropTypes.number.isRequired,
  onDelete: PropTypes.func,
  isAvailableToDelete: PropTypes.bool,
  linkParams: PropTypes.object,
};

export default Actions;
