import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  counter: {
    width: 18,
    height: 22,
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 100,
    color: theme.palette.common.white,
    lineHeight: '22px',
    textAlign: 'center',
    marginLeft: theme.spacing(1),
  },
  button: {
    padding: theme.spacing(1, 1.5),
    width: '100%',
  },
  link: {
    '& .MuiTypography-body2:first-child': {
      color: theme.palette.primary.main,
    },
  },
}));
