import { useRef } from 'react';
import { components } from 'react-select';
import Scrollbar from 'react-scrollbars-custom';
import * as PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';

const ITEM_HEIGHT = 38;
const MAX_MENU_HEIGHT = 135;
const CHECKBOX_ITEM_HEIGHT = 50;
const CHECKBOX_MAX_MENU_HEIGHT = 200;
const MAX_SHOW_OPTIONS = 4;

const SCROLL_OFFSET = 10;

const Menu = (props) => {
  const scrollbarRef = useRef(null);
  const {
    children,
    options,
    hasValue,
    isMulti,
    selectProps: { isCheckbox, value },
  } = props;
  const itemHeight = isCheckbox ? CHECKBOX_ITEM_HEIGHT : ITEM_HEIGHT;
  const maxMenuHeight = isCheckbox ? CHECKBOX_MAX_MENU_HEIGHT : MAX_MENU_HEIGHT;
  const decrement = hasValue && !isCheckbox ? (isMulti ? value?.length || 0 : 1) : 0;
  const maxOptions = Math.max(options.length - decrement, 1);
  const height = maxOptions < MAX_SHOW_OPTIONS ? maxOptions * itemHeight : maxMenuHeight;

  const handleScroll = () => {
    const { scrollHeight, clientHeight, scrollTop } = scrollbarRef.current;
    if (scrollTop + clientHeight >= scrollHeight - SCROLL_OFFSET) {
      // scroll reached end of list
      const search = props?.selectProps?.inputValue;
      const page = props?.selectProps?.page;
      props?.selectProps?.loadMoreOptions(search, page);
    }
  };
  return (
    <components.Menu {...props}>
      <Scrollbar
        style={{ height }}
        ref={scrollbarRef}
        onScroll={props?.selectProps?.captureMenuScroll && handleScroll}
      >
        {children}
        <Box sx={{ px: 2 }}>
          {props?.selectProps?.hasMoreResults && <CircularProgress size={16} color="primary" />}
        </Box>
      </Scrollbar>
    </components.Menu>
  );
};

Menu.propTypes = {
  children: PropTypes.element.isRequired,
  maxMenuHeight: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Menu;
