import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  popover: {
    '& > .MuiPaper-root': {
      borderRadius: 8,
    },
  },
  paper: {
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: 8,
    padding: theme.spacing(2),
  },
}));
