import React, { useState } from 'react';
import isArray from 'lodash/isArray';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography/Typography';
import Button from '@mui/material/Button/Button';
import { useLocation } from 'react-router-dom';
import WrapLink from '@uptime/shared/components/WrapLink';
import Actions from '@uptime/shared/screens/Marketplace/partials/Actions';

import { getName } from '@uptime/shared/utils/general';
import { EMPTY } from '@uptime/shared/components/constants';
import TechnicianAvatar from '@uptime/shared/screens/Marketplace/partials/TechnicianAvatar';
import styles from '@uptime/shared/screens/Marketplace/styles';

import MenuLoading from '../../../components/LoadingPlaceholder/MenuLoading';
import { ACTION_LINKS } from '../../../constants/marketplace';
import { getRateRangeString } from '../../../utils/marketplace';

export default (list, additionalInfo = {}) => {
  const classes = styles();
  const { isLoading } = additionalInfo;
  const [loaded, setLoaded] = useState([]);

  const { pathname, search } = useLocation();
  const backLink = `${pathname}${search}`;

  if (!isArray(list)) return [];

  return list.map((item) => {
    const {
      technicianUserId,
      businessName,
      addressLine,
      coordinates,
      rateRange,
      certificationCount,
      companyType,
      ...profile
    } = item;

    const { latitude = 0, longitude = 0 } = coordinates || {};
    const userName = getName(profile);

    const linkParams = {
      pathname: ACTION_LINKS.view(technicianUserId),
      state: { backLink },
    };

    const imageIsLoading = loaded.indexOf(technicianUserId) > -1;
    const photo = (
      <TechnicianAvatar
        userId={technicianUserId}
        userName={userName}
        onLoaded={setLoaded}
        isLoaded={imageIsLoading}
      />
    );

    const technicianFullName = (
      <Button component={WrapLink} to={linkParams}>
        <Box display="flex" alignItems="center" textAlign="initial">
          <Box className={classes.link} sx={{ paddingLeft: 0 }}>
            <Typography component="p" variant="body2">
              {userName}
            </Typography>
          </Box>
        </Box>
      </Button>
    );

    const actions = (
      <MenuLoading isLoading={isLoading}>
        <Actions
          id={technicianUserId}
          isAvailableToDelete={false}
          linkParams={linkParams}
          renderWithoutDropdown={true}
        />
      </MenuLoading>
    );

    return {
      id: technicianUserId,
      technicianFullName,
      technicianAddressLine: addressLine || EMPTY,
      technicianRateRange: getRateRangeString(rateRange),
      technicianCertificationCount: certificationCount || 0,
      technicianCompanyType: companyType || EMPTY,
      actions,
      latitude,
      longitude,
      photo,
      technicianName: userName,
      technicianBusinessName: businessName,
    };
  });
};
