import makeStyles from '@mui/styles/makeStyles';

import mapPlaceholder from '@uptime/shared/assets/map-placeholder.svg';

export default makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  clearSpace: {
    '& > div': {
      margin: 0,
      padding: 0,
      maxWidth: '100%',
    },
  },
  content: {
    position: 'relative',
    background: theme.palette.secondary.light,
    maxWidth: '100%',
    height: '100%',
  },
  whiteBackground: {
    background: theme.palette.common.white,
    '& > .MuiBox-root': {
      [theme.breakpoints.up('md')]: {
        marginLeft: '0 !important',
        paddingLeft: '0 !important',
      },
    },
    '& $tabs': {
      background: theme.palette.secondary.light,
    },
  },
  coverages: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    '& > .loader': {
      height: '100%',
      width: '100%',
      background: `center no-repeat url(${mapPlaceholder}) ${theme.palette.secondary.light}`,
      animation: '$pulse 1.5s ease-in-out 0.5s infinite',
    },
    '& > .mapboxgl-map': {
      outline: 'none',
      borderRadius: 0,
    },
  },
  tabs: {
    backgroundColor: theme.palette.common.white,
    position: 'relative',
  },
  dayContainer: {
    height: 100,
    width: '100%',
    background: theme.palette.secondary.light,
    boxSizing: 'border-box',
    borderRadius: 6,
  },
  timeSlot: {
    borderRadius: 4,
  },
  dark: {
    '& .MuiTypography-root': {
      color: theme.palette.common.white,
    },
  },
  light: {
    '& .MuiTypography-root': {
      color: theme.palette.common.black,
    },
  },
  bgGrey: {
    background: theme.palette.secondary.light,
    borderRadius: 6,
  },
  backButton: {
    position: 'absolute',
    bottom: 'calc(100% + 14px)',
    left: 0,
    [theme.breakpoints.down('md')]: {
      bottom: 'calc(100% + 8px)',
      left: 'auto',
      right: 8,
    },
    zIndex: 99,
  },
  empty: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  progress: {
    position: 'absolute',
    top: 4,
    left: 0,
    width: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    '& > div': {
      padding: theme.spacing(1),
      background: theme.palette.common.white,
      borderRadius: 6,
      '& > div': {
        width: '100%',
      },
    },
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.4,
    },
    '100%': {
      opacity: 1,
    },
  },
}));
