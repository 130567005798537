import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';

export default (list, filters) => {
  const [center, setCenter] = useState(null);

  useEffect(() => {
    if (filters?.location?.coordinates) {
      const { x: longitude, y: latitude } = filters.location.coordinates;

      const selectedLocationCoordinates = {
        longitude,
        latitude,
      };

      if (!isEqual(center, selectedLocationCoordinates)) return setCenter(selectedLocationCoordinates);

      return;
    }

    const firstSPCoordinates = list?.hits?.length && list.hits[0].coordinates;

    if (firstSPCoordinates && !isEqual(center, firstSPCoordinates)) setCenter(firstSPCoordinates);
  }, [list, filters?.location?.coordinates]); // eslint-disable-line

  return center;
};
