import React from 'react';
import Typography from '@mui/material/Typography/Typography';
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';

import styles from '../styles';

const ItemInfo = ({ label, value }) => {
  const classes = styles();

  return (
    <Box
      ml={{
        xs: 0,
        md: 2,
      }}
      display="flex"
      alignItems="center"
    >
      <Box>
        <Typography component="p" className={classes.label}>
          {label}
        </Typography>
      </Box>
      <Box ml={1}>{value}</Box>
    </Box>
  );
};

ItemInfo.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
};

export default ItemInfo;
