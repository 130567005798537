import { useState } from 'react';

import { getEquipmentMakesForCategories } from '@uptime/controller/src/graphql/devices';
import { devicesClient } from '../graphql/apolloClientConfiguration';

export default (userId = undefined) => {
  const [makes, setMakes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const doFetchMakes = async (categoryId) => {
    setIsLoading(true);
    setMakes([]);
    if (!categoryId) return;

    try {
      const {
        data: { options },
      } = await devicesClient.query({
        query: getEquipmentMakesForCategories,
        variables: {
          categoryIds: [categoryId],
          userId,
        },
      });
      setMakes(options);
    } catch {
      setMakes([]);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    doFetchMakes,
    makes,
    isLoading,
  };
};
