import { Children } from 'react';

export const point = (coordinates, properties) => ({
  type: 'Feature',
  properties,
  geometry: {
    type: 'Point',
    coordinates,
  },
});

const childrenKeys = (children) => Children.toArray(children).map((child) => child.key);

export const shallowCompareChildren = (prevChildren, newChildren) => {
  if (Children.count(prevChildren) !== Children.count(newChildren)) {
    return false;
  }

  const prevKeys = childrenKeys(prevChildren);
  const newKeys = new Set(childrenKeys(newChildren));
  return prevKeys.every((key) => newKeys.has(key));
};
