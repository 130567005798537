import React from 'react';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import trim from 'lodash/trim';
import useContainerWidth from '@uptime/shared/hooks/useContainerWidth';

import Input from '@uptime/shared/components/Input';
import useStyles from './styles';
import SearchSwitcher from './SearchSwitcher';

const Search = (props) => {
  const classes = useStyles();
  const {
    disabled,
    children,
    onSubmit,
    onReset,
    initialValues,
    options,
    onOptionClick,
    isOnChange,
    className,
    placeholder,
    onSwitch,
    isDisabledTrimming,
    margin = 0,
    hideSearch = false,
    isLowHeight,
  } = props;
  const boxClasses = classNames({
    [classes.root]: true,
    [className]: true,
    [classes.hide]: hideSearch,
  });
  const [containerRef, containerWidth] = useContainerWidth();

  const dropDownStyles = {
    width: containerWidth,
  };

  const renderForm = ({ handleSubmit }) => {
    const eventHandler = isOnChange ? { onChangeCustom: handleSubmit } : {};

    return (
      <form className={classes.form} onSubmit={handleSubmit}>
        <Field
          disabled={disabled}
          autoComplete="off"
          autofill="off"
          name="search"
          placeholder={placeholder || 'Search…'}
          component={Input}
          options={options}
          onOptionClick={onOptionClick}
          dropDownStyles={dropDownStyles}
          className={isLowHeight ? classes.lowHeight : null}
          startAdornment={<SearchIcon className={classes.searchIcon} />}
          {...eventHandler}
          data-testid="search"
        />
      </form>
    );
  };

  renderForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  const form = (
    <Form
      render={renderForm}
      onSubmit={(values) => {
        const trimmedSearch = {
          ...values,
          search: trim(values.search),
        };

        onSubmit(isDisabledTrimming ? values : trimmedSearch);
      }}
      initialValues={initialValues}
    />
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      position="relative"
      zIndex="2"
      className={boxClasses}
      m={Number(margin)}
      ref={containerRef}
    >
      {onSwitch && <SearchSwitcher onSwitch={onSwitch} />}
      {form}
      {initialValues.search && (
        <IconButton onClick={onReset} size="large">
          <CancelIcon />
        </IconButton>
      )}
      {children}
    </Box>
  );
};

Search.propTypes = {
  isOnChange: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  onOptionClick: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onSwitch: PropTypes.func,
  initialValues: PropTypes.object,
  isDisabledTrimming: PropTypes.bool,
  margin: PropTypes.number,
  hideSearch: PropTypes.bool,
};

export default Search;
