import React from 'react';
import * as PropTypes from 'prop-types';

import { BarberPoleContainer, StyledBarberPole, MessageContainer } from './styles';

const BarberPole = (props) => {
  const { message } = props;

  return (
    <BarberPoleContainer>
      <StyledBarberPole />
      {message && <MessageContainer>{message}</MessageContainer>}
    </BarberPoleContainer>
  );
};

BarberPole.propTypes = {
  message: PropTypes.string,
};

export default BarberPole;
