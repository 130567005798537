import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography/Typography';
import Button from '@mui/material/Button/Button';
import uuid from 'uuid';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import * as PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import classNames from 'classnames';

import useIsMobileView from '@uptime/shared/hooks/useIsMobileView';
import WrapLink from '../WrapLink';
import PageActions from '../PageActions';
import useStyles, { ProgressContainer } from './styles';

const Page = ({
  title,
  leftNav,
  children,
  filters,
  settings,
  actions = [],
  additional,
  additionalAllScreenSizes,
  containerRef,
  isLoading,
  isNoSpace,
  className,
}) => {
  const classes = useStyles();
  const isMobile = useIsMobileView();

  const containerReference = containerRef ? { ref: containerRef } : {};
  const showActions = actions.some(({ shouldRender }) => shouldRender);
  const containerClasses = classNames(className, classes.root, {
    [classes.noSpace]: isNoSpace,
  });

  return (
    <div className={containerClasses}>
      {isLoading && (
        <Box position="fixed" top={70} left={0} width="100%" zIndex={1}>
          <ProgressContainer>
            <LinearProgress />
          </ProgressContainer>
        </Box>
      )}
      <div {...containerReference}>
        {!isMobile && (
          <Box alignItems="center" position="relative" zIndex="3" display="flex">
            {leftNav && <Box width="100%">{leftNav}</Box>}
            {title && (
              <Box flex={'0 1 auto'} mb={1}>
                <Typography variant="h2">{title}</Typography>
              </Box>
            )}
            <Box
              display="flex"
              alignItems="center"
              flex={1}
              className={classes.controls}
              justifyContent="flex-end"
              mb={1}
              ml={4}
            >
              {additional}
              {additionalAllScreenSizes}
              {showActions &&
                actions.map(
                  ({ url, title, icon, component, href, shouldRender = true }) =>
                    shouldRender && (
                      <Button
                        variant="contained"
                        {...(icon ? { color: 'primary' } : {})}
                        component={component || WrapLink}
                        to={url}
                        href={href}
                        startIcon={icon}
                        key={uuid.v4()}
                      >
                        {title}
                      </Button>
                    )
                )}
              {settings}
              {filters}
            </Box>
          </Box>
        )}

        {isMobile && (
          <Box alignItems="center" justifyContent="space-between" zIndex="3" display="flex" flexWrap="wrap">
            {leftNav && <Box width="100%">{leftNav}</Box>}
            <Typography variant="h2">{title}</Typography>
            <Box display="flex" ml="auto">
              {additionalAllScreenSizes}
              <Box display="flex" alignItems="center" mr={-1}>
                {settings}
                {filters}
                {(showActions || additional) && (
                  <PageActions>
                    {additional}
                    {actions &&
                      actions.map(
                        ({ url, title, component, href, shouldRender = true }) =>
                          shouldRender && (
                            <MenuItem component={component || WrapLink} to={url} href={href} key={uuid.v4()}>
                              <ListItemText primary={title} />
                            </MenuItem>
                          )
                      )}
                  </PageActions>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </div>
      {children}
    </div>
  );
};

Page.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  additional: PropTypes.node,
  additionalAllScreenSizes: PropTypes.node,
  leftNav: PropTypes.node,
  children: PropTypes.node.isRequired,
  filters: PropTypes.node,
  settings: PropTypes.node,
  containerRef: PropTypes.object,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      component: PropTypes.string,
      href: PropTypes.string,
    }).isRequired
  ),
  isLoading: PropTypes.bool,
  isNoSpace: PropTypes.bool,
  className: PropTypes.string,
};

Page.defaultProps = {
  isLoading: false,
};

export default Page;
