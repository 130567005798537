import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
    card: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 4,
        '& > .MuiBox-root': {
            marginTop: 0,
        }
    },
    cardContent: {
        padding: '0 !important',
    },
    header: {
        padding: '0 !important',
        '& .MuiCardHeader-content, & .MuiCardHeader-action': {
            border: 'none',
            padding: '0 !important',
        }
    },
    map: {
        borderRadius: 6,
        '& .mapboxgl-canvas': {
            position: 'static',
        },
    },
    placeholder: {
        borderRadius: 6,
        backgroundColor: theme.palette.grey[300],
        height: '100%',
    },
    point: {
        backgroundColor: `${theme.palette.primary.main}50`,
        border: `1px solid ${theme.palette.primary.main}`,
        userSelect: 'none',
        pointerEvents: 'none',
    },
    deleteButton: {
        color: theme.palette.error.main,
    }
}));