import makeStyles from '@mui/styles/makeStyles';
export default makeStyles((theme) => ({
  toggleAction: {
    borderRadius: '10%',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light2,
    },
  },
  viewButton: {
    borderRadius: '10%',
    '&:hover': {
      backgroundColor: theme.palette.primary.light2,
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
      },
    },
  },
}));
