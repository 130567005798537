import { useEffect, useRef, useState } from 'react';
import { useIsMounted } from '@uptime/shared/hooks';

export default () => {
  const containerRef = useRef();
  const isMounted = useIsMounted();
  const [containerWidth, setContainerWidth] = useState(0);

  const handleWindowSize = () => {
    isMounted && setContainerWidth(containerRef.current && containerRef.current.clientWidth);
  };

  useEffect(() => {
    isMounted && window.addEventListener('resize', handleWindowSize);
    return () => window.addEventListener('resize', handleWindowSize);
  }, []); // eslint-disable-line

  useEffect(() => {
    isMounted && handleWindowSize();
    return () => setContainerWidth(0);
  }, [containerRef]); // eslint-disable-line

  return [containerRef, containerWidth];
};
