import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import * as PropTypes from 'prop-types';

const MenuLoading = ({ isLoading, children }) =>
  isLoading ? (
    <Box display="flex" justifyContent="center" alignItems="center" width={40} height={40} mr={1}>
      <CircularProgress color="inherit" size={20} />
    </Box>
  ) : (
    children
  );

MenuLoading.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default MenuLoading;
