import { gql } from '@apollo/client';

const COLUMN_INFO = `
fragment columnInfo on SettingColumn {
    columnId
    isActive
    position
    title
}
`;

const SETTINGS = `
    technicianFullName {
        ...columnInfo
    },
    technicianBusinessName {
        ...columnInfo
    },
    technicianAddressLine {
        ...columnInfo
    },
    technicianFileId {
        ...columnInfo
    },
    technicianSchedule {
        ...columnInfo
    },
    facilityCount {
        ...columnInfo
    },
    deviceCount {
        ...columnInfo
    },
    workOrderCount {
        ...columnInfo
    }
    serviceProviderStatus {
        ...columnInfo
    }
`;

const WORKING_HOURS = `
fragment workingHours on WorkingHours {
    from
    to
}
fragment scheduleFragment on ServiceSchedule {
    mon {
        ...workingHours
    }
    tue {
        ...workingHours
    }
    wed {
        ...workingHours
    }
    thu {
        ...workingHours
    }
    fri {
        ...workingHours
    }
    sat {
        ...workingHours
    }
    sun {
        ...workingHours
    }
}
`;

export const getSettings = gql`
    ${COLUMN_INFO}
    query serviceProviderListSettings(
        $userId: Int!
    ) {
        settings: serviceProviderListSettings(
            userId: $userId
        ) {
            ${SETTINGS}
        }
    }
`;

export const getContractTypes = gql`
  query getContractTypes($userId: Int!) {
    types: contractTypes(userId: $userId) {
      value: id
      label: title
      alias
    }
  }
`;

export const getTechnicianProfileByEmail = gql`
  query technicianProfileByEmail($userId: Int!, $email: String!) {
    profile: technicianProfileByEmail(userId: $userId, email: $email) {
      technicianUserId
      email
      firstName
      lastName
      accountStatus
    }
  }
`;

export const getServiceProvidersForDevice = gql`
  query serviceProvidersForDevice($userId: Int!) {
    serviceProvidersForDevice(userId: $userId) {
      value: id
      publicId
      deviceCount
      technician {
        businessName
        firstName
        lastName
      }
      spFacilities {
        facilityId
      }
      spCategories {
        equipmentCategoryId
        facilityId
      }
      supportType
    }
  }
`;

export const getServiceProviders = gql`
  query serviceProviderList(
    $userId: Int!
    $page: Int!
    $sortBy: [ServiceProviderSortInput!] = [
      { field: technicianFirstName, order: ASC }
      { field: technicianLastName, order: ASC }
    ]
    $search: String
    $polygon: PolygonInput
    $internalPolygon: PolygonInput
    $itemsAmount: Int = 10
  ) {
    serviceProviders: serviceProviderList(
      userId: $userId
      pagination: { page: $page, itemsAmount: $itemsAmount }
      sortBy: $sortBy
      searchBy: $search
      polygon: $polygon
      internalPolygon: $internalPolygon
    ) {
      hits {
        id
        publicId
        technicianUserId
        facilityUserId
        deviceCount
        workOrderCount
        facilityCount
        status
        canCreate
        signature
        supportType
        technician {
          businessName
          firstName
          lastName
          addressLine
          coordinates {
            latitude: y
            longitude: x
          }
        }
        spCategories {
          serviceProviderId
          facilityId
          rate
          equipmentCategoryId
          equipmentCategoryTitle
          createdAt
          updatedAt
        }
        spFacilities {
          serviceProviderId
          facilityId
          facilityTitle
          createdAt
          updatedAt
        }
      }
      pageCount
      currentPage
      itemsCount
    }
  }
`;

export const getAssignmentList = gql`
  query assignmentList($filter: AssignmentListFilterInput!, $page: Int!, $itemsAmount: Int = 10) {
    assignmentList(filter: $filter, pagination: { page: $page, itemsAmount: $itemsAmount }) {
      hits {
        id
        accountId
        facilityId
        facilityName
        technician1 {
          id
          name
          email
          phone
        }
        technician1Timeout
        technician2 {
          id
          name
          email
          phone
        }
        technician2Timeout
        technician3 {
          id
          name
          email
          phone
        }
        technician3Timeout
        technician4 {
          id
          name
          email
          phone
        }
        technician4Timeout
        technician5 {
          id
          name
          email
          phone
        }
      }
      currentPage
      itemsCount
      pageCount
    }
  }
`;

export const getMarketplaceList = gql`
  query marketplaceList(
    $userId: Int
    $page: Int! = 1
    $sortBy: [MarketplaceSortInput!] = [
      { field: technicianFirstName, order: ASC }
      { field: technicianLastName, order: ASC }
    ]
    $polygon: PolygonInput
    $filters: MarketplaceFilterInput
    $internalPolygon: PolygonInput
    $itemsAmount: Int = 10
  ) {
    list: marketplaceList(
      userId: $userId
      pagination: { page: $page, itemsAmount: $itemsAmount }
      sortBy: $sortBy
      filter: $filters
      polygon: $polygon
      internalPolygon: $internalPolygon
    ) {
      hits {
        technicianUserId
        businessName
        firstName
        lastName
        addressLine
        rateRange
        companyType
        certificationCount
        coordinates {
          latitude: y
          longitude: x
        }
      }
      pageCount
      currentPage
      itemsCount
    }
  }
`;

export const getSPOptions = gql`
  query serviceProviderList($userId: Int!) {
    serviceProviderList(
      userId: $userId
      sortBy: [{ field: technicianFirstName, order: ASC }, { field: technicianLastName, order: ASC }]
      filter: { status: confirmed }
    ) {
      hits {
        id
        technicianUserId
        technician {
          businessName
          firstName
          lastName
          email
        }
        supportType
        spFacilities {
          facilityId
        }
        spCategories {
          equipmentCategoryId
          facilityId
        }
      }
    }
  }
`;

export const getServiceProvider = gql`
  query serviceProvider($userId: Int!, $technicianUserId: Int!) {
    serviceProvider: serviceProvider(facilityUserId: $userId, technicianUserId: $technicianUserId) {
      id
      publicId
      facilityUserId
      technicianUserId
      deviceCount
      workOrderCount
      facilityCount
      status
      technician {
        technicianUserId
        accountStatus
        firstName
        lastName
        email
        businessName
        addressLine
        coordinates {
          latitude: y
          longitude: x
        }
      }
      spCategories {
        serviceProviderId
        facilityId
        rate
        equipmentCategoryId
        equipmentCategoryTitle
        createdAt
        updatedAt
      }
      spFacilities {
        serviceProviderId
        facilityId
        facilityTitle
        createdAt
        updatedAt
      }
    }
  }
`;

export const getServiceProviderOption = gql`
  query serviceProvider($facilityUserId: Int!, $technicianUserId: Int!) {
    serviceProvider: serviceProvider(facilityUserId: $facilityUserId, technicianUserId: $technicianUserId) {
      id
      technician {
        firstName
        lastName
        businessName
      }
    }
  }
`;

export const getFullServiceProviderById = gql`
  query serviceProviderById($userId: Int!, $serviceProviderId: Int!) {
    serviceProvider: serviceProviderById(userId: $userId, serviceProviderId: $serviceProviderId) {
      id
      publicId
      facilityUserId
      technicianUserId
      deviceCount
      workOrderCount
      facilityCount
      status
      canCreate
      supportType
      technician {
        technicianUserId
        accountStatus
        firstName
        lastName
        email
        businessName
        addressLine
        coordinates {
          latitude: y
          longitude: x
        }
      }
      spFacilities {
        serviceProviderId
        facilityId
        facilityTitle
      }
      spCategories {
        serviceProviderId
        facilityId
        rate
        equipmentCategoryId
        equipmentCategoryTitle
      }
    }
  }
`;

export const getServiceProviderContracts = gql`
  query serviceProviderContracts($userId: Int!, $serviceProviderId: Int!) {
    contracts: serviceProviderContracts(userId: $userId, serviceProviderId: $serviceProviderId) {
      id
      serviceProviderId
      facilityId
      number
      typeId
      start
      end
      paymentMethod
      value
      type {
        id
        title
      }
    }
  }
`;

export const getServiceProviderById = gql`
  query serviceProviderById($userId: Int!, $serviceProviderId: Int!) {
    profile: serviceProviderById(userId: $userId, serviceProviderId: $serviceProviderId) {
      id
      technician {
        firstName
        lastName
        businessName
      }
    }
  }
`;

export const getServiceTypes = gql`
  query serviceTypes($userId: Int) {
    serviceTypes(userId: $userId) {
      value: id
      label: title
      alias
    }
  }
`;

export const getTechnicianServices = gql`
  query technicianServices($userId: Int!) {
    services: technicianServices(technicianUserId: $userId) {
      id
      typeId
      rate
      color
      description
      schedule {
        mon {
          from
          to
        }
        tue {
          from
          to
        }
        wed {
          from
          to
        }
        thu {
          from
          to
        }
        fri {
          from
          to
        }
        sat {
          from
          to
        }
        sun {
          from
          to
        }
      }
      type {
        id
        title
        alias
      }
    }
  }
`;

export const getTechnicianCoverages = gql`
  query technicianAreas($userId: Int!) {
    coverages: technicianAreas(technicianUserId: $userId) {
      id
      city: title
      isNationwide: nationwide
      coordinates {
        longitude: x
        latitude: y
      }
      distance: range
    }
  }
`;

export const getTechnicianEquipments = gql`
  query technicianEquipments(
    $userId: Int!
    $page: Int!
    $sortBy: [TechnicianEquipmentSortInput!] = [
      { field: equipmentCategoryTitle, order: ASC }
      { field: equipmentMakeTitle, order: ASC }
    ]
    $itemsAmount: Int = 10
  ) {
    equipments: technicianEquipments(
      technicianUserId: $userId
      sortBy: $sortBy
      pagination: { page: $page, itemsAmount: $itemsAmount }
    ) {
      hits {
        id
        technicianUserId
        equipmentCategoryId
        equipmentCategoryTitle
        equipmentMakeId
        equipmentMakeTitle
        isCertified
      }
      pageCount
      currentPage
      itemsCount
    }
  }
`;

// Mutations

export const onCreateTechnicianProfile = gql`
  mutation createTechnicianProfile($userId: Int!, $technicianData: TechnicianCreationInput!) {
    createTechnicianProfile(userId: $userId, technicianData: $technicianData) {
      technicianUserId
      accountStatus
      firstName
      lastName
      email
    }
  }
`;

export const onAddServiceProvider = gql`
  mutation addServiceProvider($userId: Int!, $technicianUserId: Int!, $canCreate: Boolean) {
    addServiceProvider(facilityUserId: $userId, technicianUserId: $technicianUserId, canCreate: $canCreate) {
      id
      publicId
      signature
      spCategories {
        serviceProviderId
        facilityId
        rate
        equipmentCategoryId
        equipmentCategoryTitle
        createdAt
        updatedAt
      }
      spFacilities {
        serviceProviderId
        facilityId
        facilityTitle
        createdAt
        updatedAt
      }
    }
  }
`;

export const onSaveServiceProviderEquipments = gql`
  mutation saveServiceProviderEquipments(
    $userId: Int!
    $serviceProviderId: Int!
    $equipmentsData: ServiceProviderEquipmentInput!
    $supportType: String
  ) {
    equipments: saveServiceProviderEquipments(
      userId: $userId
      serviceProviderId: $serviceProviderId
      equipmentsData: $equipmentsData
      supportType: $supportType
    ) {
      facilityId
      facilityTitle
      equipmentCategoryId
      equipmentCategoryTitle
      rate
    }
  }
`;

export const onAddServiceProviderContract = gql`
  mutation addServiceProviderContract($userId: Int!, $contractData: AddServiceProviderContractInput!) {
    addServiceProviderContract(userId: $userId, contractData: $contractData) {
      id
      serviceProviderId
      facilityId
      number
      typeId
      start
      end
      paymentMethod
      value
      createdAt
      updatedAt
    }
  }
`;

export const onUpdateServiceProviderContract = gql`
  mutation updateServiceProviderContract(
    $userId: Int!
    $serviceProviderId: Int!
    $contractId: String!
    $typeId: Int!
    $start: Int
    $end: Int
    $paymentMethod: PaymentMethod!
    $value: Float
  ) {
    updateServiceProviderContract(
      userId: $userId
      serviceProviderId: $serviceProviderId
      contractId: $contractId
      updatedData: { typeId: $typeId, start: $start, end: $end, paymentMethod: $paymentMethod, value: $value }
    ) {
      id
    }
  }
`;

export const onSendConfirmationEmail = gql`
  mutation sendConfirmationEmail($userId: Int!, $serviceProviderId: Int!) {
    isSent: sendConfirmationEmail(userId: $userId, serviceProviderId: $serviceProviderId)
  }
`;

export const onUpdateSettings = gql`
    ${COLUMN_INFO}
    mutation updateServiceProviderListSettings(
        $userId: Int!
        $settings: ServiceProviderListSettingsInput!
    ){
        updateServiceProviderListSettings(
            userId: $userId
            settings: $settings
        ) {
            ${SETTINGS}
        }
    }
`;

export const onDeleteContracts = gql`
  mutation deleteServiceProviderContracts($userId: Int!, $serviceProviderId: Int!, $contractIds: [String!]!) {
    deleteServiceProviderContracts(
      userId: $userId
      serviceProviderId: $serviceProviderId
      contractIds: $contractIds
    )
  }
`;

export const onAddTechnicianServices = gql`
  ${WORKING_HOURS}
  mutation addTechnicianServices($userId: Int!, $servicesData: [AddTechnicianServiceInput]!) {
    services: addTechnicianServices(technicianUserId: $userId, servicesData: $servicesData) {
      id
      typeId
      rate
      color
      description
      schedule {
        ...scheduleFragment
      }
    }
  }
`;

export const onUpdateTechnicianService = gql`
  ${WORKING_HOURS}
  mutation updateTechnicianService(
    $userId: Int!
    $serviceId: Int!
    $servicePayload: UpdateTechnicianServiceInput!
  ) {
    services: updateTechnicianService(
      technicianUserId: $userId
      serviceId: $serviceId
      servicePayload: $servicePayload
    ) {
      id
      typeId
      rate
      color
      description
      schedule {
        ...scheduleFragment
      }
    }
  }
`;

export const onDeleteTechnicianServices = gql`
  mutation deleteTechnicianServices($userId: Int!, $serviceIds: [Int]!) {
    isDeleted: deleteTechnicianServices(technicianUserId: $userId, serviceIds: $serviceIds)
  }
`;

export const onAddTechnicianAreas = gql`
  mutation addTechnicianAreas($userId: Int!, $areasData: [TechnicianAreaInput]!) {
    coverages: addTechnicianAreas(technicianUserId: $userId, areasData: $areasData) {
      id
      city: title
      coordinates {
        longitude: x
        latitude: y
      }
      distance: range
    }
  }
`;

export const onDeleteTechnicianAreas = gql`
  mutation deleteTechnicianAreas($userId: Int!, $areaIds: [Int]!) {
    isDeleted: deleteTechnicianAreas(technicianUserId: $userId, areaIds: $areaIds)
  }
`;

export const onAddTechnicianEquipments = gql`
  mutation addTechnicianEquipments($userId: Int!, $equipmentsData: [TechnicianEquipmentInput]!) {
    equipments: addTechnicianEquipments(technicianUserId: $userId, equipmentsData: $equipmentsData) {
      id
      equipmentCategoryId
      equipmentCategoryTitle
      equipmentMakeId
      equipmentMakeTitle
      isCertified
    }
  }
`;

export const onDeleteTechnicianEquipments = gql`
  mutation deleteTechnicianEquipments($userId: Int!, $equipmentIds: [Int]!) {
    isDeleted: deleteTechnicianEquipments(technicianUserId: $userId, equipmentIds: $equipmentIds)
  }
`;

export const createSignatureMutation = gql`
  mutation addServiceProvider($facilityUserId: Int!, $technicianUserId: Int!, $canCreate: Boolean) {
    addServiceProvider(
      facilityUserId: $facilityUserId
      technicianUserId: $technicianUserId
      canCreate: $canCreate
    ) {
      signature
    }
  }
`;

export const updateServiceProviderSignature = gql`
  mutation updateServiceProviderSignature($id: Int!, $canCreate: Boolean!) {
    updateServiceProviderSignature(id: $id, canCreate: $canCreate) {
      signature
    }
  }
`;

export const updateSignatureMutation = gql`
  mutation updateServiceProviderSignature($id: Int!, $canCreate: Boolean!) {
    updateServiceProviderSignature(id: $id, canCreate: $canCreate) {
      signature
    }
  }
`;

export const getServiceProvidersEquipmentsByPolicy = gql`
  query getServiceProvidersEquipmentsByPolicy($filter: EquipmentFilterInput) {
    serviceProvidersEquipmentsByPolicy(filter: $filter) {
      equipmentCategoryTitle
      rate
    }
  }
`;

export const getServiceProvidersContractsByPolicy = gql`
  query getServiceProvidersContractsByPolicy($filter: ContractFilterInput) {
    serviceProvidersContractsByPolicy(filter: $filter) {
      id
      serviceProviderId
      facilityId
      number
      typeId
      start
      end
      paymentMethod
      value
      type {
        id
        title
      }
      serviceProvider {
        id
        facilityUserId
        technicianUserId
        technician {
          businessName
        }
      }
    }
  }
`;

export const removeAssignmentForFacility = gql`
  mutation removeAssignmentForFacility($assignmentId: Int!) {
    removeAssignmentForFacility(id: $assignmentId)
  }
`;
