import React, { useState } from 'react';
import { Marker } from '@urbica/react-map-gl';
import * as PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import uuid from 'uuid';

import { getAngle, getCoordinatesOnCircle } from '@uptime/shared/utils/geo';
import { LENGTH_FROM_CLUSTER_TO_MARKERS } from '@uptime/shared/constants';
import PinIcon from '@uptime/shared/components/Map/PinIcon';
import styles from '../styles';

const ClusterMarker = ({ longitude, latitude, pointCount, items }) => {
  const classes = styles();

  const [isOpen, setIsOpen] = useState(false);

  const angle = getAngle(pointCount);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <Marker longitude={longitude} latitude={latitude}>
      <ClickAwayListener onClickAway={handleClose}>
        <Box position="relative" className="cluster">
          <PinIcon onClick={handleOpen}>{pointCount}</PinIcon>
          {isOpen && (
            <>
              <Box position="absolute" top={0} left={0}>
                {items.map((item, i) => {
                  const { top, left } = getCoordinatesOnCircle(i, angle);

                  const styles = {
                    position: 'absolute',
                    top,
                    left,
                  };

                  return (
                    <div key={uuid.v4()} style={styles}>
                      {item}
                    </div>
                  );
                })}
              </Box>
              <Box className={classes.vectorContainer}>
                <svg width="100" height="100%">
                  {items.map((item, i) => {
                    const { top, left } = getCoordinatesOnCircle(i, angle);

                    return (
                      <line
                        x1={LENGTH_FROM_CLUSTER_TO_MARKERS}
                        y1={LENGTH_FROM_CLUSTER_TO_MARKERS}
                        x2={LENGTH_FROM_CLUSTER_TO_MARKERS + left}
                        y2={LENGTH_FROM_CLUSTER_TO_MARKERS + top}
                        className={classes.line}
                        key={uuid.v4()}
                      />
                    );
                  })}
                </svg>
              </Box>
            </>
          )}
        </Box>
      </ClickAwayListener>
    </Marker>
  );
};

ClusterMarker.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  pointCount: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.node),
};

export default ClusterMarker;
