import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    height: 12,
    width: 12,
    borderRadius: '50%',
  },
  shadow: {
    backgroundColor: `${theme.palette.primary.main}40`,
    height: 48,
    width: 48,
    borderRadius: 'inherit',
    position: 'absolute',
    top: -18,
    left: -18,
    pointerEvents: 'none',
  },
  pin: {
    height: 12,
    width: 12,
    minWidth: 12,
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 'inherit',
    border: '2px solid transparent',
    borderColor: theme.palette.common.white,
    color: theme.palette.common.white,
    boxShadow: '0 1px 8px rgba(0, 0, 0, 0.4)',
    boxSizing: 'initial',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transitionProperty: 'height, width, min-width, border-width',
    transitionDuration: '100ms',
  },
  count: {
    minWidth: 24,
    height: 24,
    zIndex: 3,
    cursor: 'pointer',
    '&:hover': {
      zIndex: 4,
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      borderWidth: 1,
    },
  },
  hover: {
    cursor: 'pointer',
    '&:hover': {
      zIndex: 2,
      transitionProperty: 'height, width, min-width, border-width',
      transitionDuration: '100ms',
      height: 24,
      width: 24,
      minWidth: 24,
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      '&::before': {
        content: '""',
        display: 'block',
        borderRadius: 'inherit',
        width: 8,
        height: 8,
        minWidth: 8,
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  title: {
    background: theme.palette.common.white,
    zIndex: 1,
    borderRadius: 4,
    padding: theme.spacing(0, 1),
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
  },
}));
