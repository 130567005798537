import React, { useEffect } from 'react';
import * as PropTypes from 'prop-types';

import { usePrevious } from '@uptime/shared/hooks';

const ResetLocation = (props) => {
  const { city, onChange } = props;
  const previousCity = usePrevious(city);

  useEffect(() => {
    if (!city && previousCity !== city) onChange();
  }, [city]); // eslint-disable-line

  return <></>;
};

ResetLocation.propTypes = {
  city: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ResetLocation;
