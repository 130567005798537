import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import styles from '@uptime/shared/screens/Marketplace/View/styles';

export default () => {
  const classes = styles();

  return (
    <Box className={classes.tabs} display="flex" pt={1.5} pb={1.5} m={3}>
      <Box pl={3} pr={3}>
        <Skeleton variant="text" height={36} width={120} />
      </Box>
      <Box pl={3} pr={3}>
        <Skeleton variant="text" height={36} width={110} />
      </Box>
      <Box pl={3} pr={3}>
        <Skeleton variant="text" height={36} width={70} />
      </Box>
      <Box pl={3} pr={3}>
        <Skeleton variant="text" height={36} width={70} />
      </Box>
    </Box>
  );
};
