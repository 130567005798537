import { compose, withProps } from 'recompose';

import withSnackMessageHOC from '@uptime/shared/HOCs/withSnackMessageHOC';
import Marketplace from '../components/Marketplace';

const propsHOC = withProps({
  context: {
    accountId: null,
  },
});

export default compose(withSnackMessageHOC, propsHOC)(Marketplace);
