import React from 'react';
import Typography from '@mui/material/Typography/Typography';
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';

const Slot = ({ title, caption, width = '25%', className, color, isFixedWidth }) => (
  <Box
    width={
      isFixedWidth
        ? width
        : {
            sm: width,
          }
    }
    className={className}
    height="100%"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    pl={2}
    pr={2}
    ml={0.5}
    mr={0.5}
    style={{ background: color }}
  >
    <Typography variant="subtitle1" noWrap>
      <strong>{title}</strong>
    </Typography>
    <Typography variant="caption" noWrap>
      {caption}
    </Typography>
  </Box>
);

Slot.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  caption: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  width: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  isFixedWidth: PropTypes.bool,
};

export default Slot;
