import React from 'react';
import classNames from 'classnames';
import { MenuItem, Checkbox } from '@mui/material';
import * as PropTypes from 'prop-types';

import { useStyles } from '@uptime/shared/styles/FormElementStyles';

const Option = ({ innerRef, isFocused, isSelected, innerProps, children, selectProps }) => {
  const classes = useStyles();
  const selectedClass = classNames({
    [classes.selected]: isSelected,
    [classes.option]: true,
  });
  return (
    <MenuItem ref={innerRef} selected={isFocused} component="div" className={selectedClass} {...innerProps}>
      {selectProps?.isCheckbox && <Checkbox checked={isSelected} />}
      <div className={classes.optionText} title={children}>
        {children}
      </div>
    </MenuItem>
  );
};

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    key: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
  }).isRequired,
  selectProps: PropTypes.shape({
    isCheckbox: PropTypes.bool.isRequired,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default Option;
