import React from 'react';

import SnackMessage from '../components/SnackMessage';

const withSnackMessageHOC = (Component) => (props) => {
  const {
    location: { state },
    history,
  } = props;
  const { snack, ...rest } = state || {};

  const handleChangeState = () =>
    history.replace({
      state: { ...rest },
    });

  return (
    <>
      {snack && <SnackMessage {...snack} onClose={handleChangeState} />}
      <Component {...props} />
    </>
  );
};

export default withSnackMessageHOC;
