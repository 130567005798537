import * as PropTypes from 'prop-types';

const MenuList = (props) => {
  return props.children;
};

MenuList.propTypes = {
  children: PropTypes.node,
};

export default MenuList;
