import { ACTION_TYPES } from '../constants';

export const getInitialState = () => ({
  polygon: undefined,
  addressOptions: [],
  search: undefined,
  searchTimer: undefined,
  requestTimer: undefined,
  isSearchingByAddress: true,
  isLoading: false,
});

export const ItemsOnMapReducer = (initialState) => (state, action) => {
  switch (true) {
    case action.type === ACTION_TYPES.SET_POLYGON:
      return { ...state, polygon: action.payload.polygon };

    case action.type === ACTION_TYPES.SET_ADDRESS_OPTIONS:
      return { ...state, addressOptions: action.payload.addressOptions };

    case action.type === ACTION_TYPES.SET_SEARCH:
      return { ...state, search: action.payload.search };

    case action.type === ACTION_TYPES.SET_SEARCH_TIMER:
      return { ...state, searchTimer: action.payload.searchTimer };

    case action.type === ACTION_TYPES.SET_REQUEST_TIMER:
      return { ...state, requestTimer: action.payload.requestTimer };

    case action.type === ACTION_TYPES.SET_SEARCHING_BY_ADDRESS:
      return { ...state, isSearchingByAddress: action.payload.isSearchingByAddress };

    case action.type === ACTION_TYPES.SET_LOADING:
      return { ...state, isLoading: action.payload.isLoading };

    default:
      return initialState;
  }
};
