import React, { useState, memo } from 'react';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import * as PropTypes from 'prop-types';

import PinIcon from '@uptime/shared/components/Map/PinIcon';
import { comparePropsForMarker } from '@uptime/shared/utils/geo';
import useStyles from './styles';
import Info from './Info';

const ItemMarker = (props) => {
  const classes = useStyles();
  const {
    item: { photo, actions, mapTitle },
    view,
    isShowTitle,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggle = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <PinIcon onClick={handleToggle} isHoverEnabled title={isShowTitle && mapTitle} />
      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleToggle}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          className={classes.popover}
        >
          <Paper className={classes.paper}>
            <Info actions={actions} view={view} photo={photo} />
          </Paper>
        </Popover>
      )}
    </>
  );
};

ItemMarker.propTypes = {
  item: PropTypes.shape({
    photo: PropTypes.node,
    actions: PropTypes.node.isRequired,
  }).isRequired,
  view: PropTypes.node,
  isShowTitle: PropTypes.bool,
};

export default memo(ItemMarker, comparePropsForMarker);
