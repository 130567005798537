import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography/Typography';
import * as PropTypes from 'prop-types';

import ItemInfo from '@uptime/shared/components/ItemsOnMap/partials/ItemInfo';
import useStyles from '@uptime/controller/src/components/Facilities/FacilitiesOnMap/FacilityMarker/styles';

const MarkerView = (props) => {
  const classes = useStyles();
  const {
    technicianName,
    technicianBusinessName,
    technicianAddressLine,
    technicianRateRange,
    technicianCompanyType,
    technicianCertificationCount,
  } = props;

  return (
    <>
      <Box
        ml={2}
        display="flex"
        alignItems="baseline"
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
      >
        <Typography variant="h3" className={classes.title}>
          {technicianName}
        </Typography>
        <Box mt={{ xs: 2, md: 0 }} />
        <ItemInfo label="Company" value={technicianBusinessName} />
      </Box>
      <Box
        mt={2}
        ml={{
          xs: 2,
          md: 0,
        }}
      >
        <ItemInfo label="Address" value={technicianAddressLine} />
      </Box>
      <Box
        mt={2}
        display="flex"
        alignItems={{
          md: 'center',
        }}
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        ml={{
          xs: 2,
          md: 0,
        }}
      >
        <ItemInfo label="Rate" value={technicianRateRange} />
        <Box mt={{ xs: 2, md: 0 }} />
        <ItemInfo label="Company Type" value={technicianCompanyType} />
      </Box>
      <Box
        mt={2}
        display="flex"
        ml={{
          xs: 2,
          md: 0,
        }}
      >
        <ItemInfo label="Certifications" value={technicianCertificationCount} />
      </Box>
    </>
  );
};

MarkerView.propTypes = {
  technicianName: PropTypes.node.isRequired,
  technicianBusinessName: PropTypes.string.isRequired,
  technicianAddressLine: PropTypes.string.isRequired,
  technicianRateRange: PropTypes.string.isRequired,
  technicianCompanyType: PropTypes.string.isRequired,
  technicianCertificationCount: PropTypes.number.isRequired,
};

export default MarkerView;
