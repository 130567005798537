import React from 'react';
import ReactMapGL, { NavigationControl } from '@urbica/react-map-gl';
import * as PropTypes from 'prop-types';
import 'mapbox-gl/dist/mapbox-gl.css';

const REACT_APP_MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoidmxhZHQtdXB0aW1laGVhbHRoIiwiYSI6ImNrNWk0eno4czA5enozbHM2OTkwdXYzbDYifQ.3s5Co-9SGoDZ4tvLImBWsg';

const Map = (props) => {
  const { isShowControls, children, mapRef, ...otherProps } = props;

  return (
    <ReactMapGL
      style={{
        width: '100%',
        height: '100%',
      }}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      accessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
      ref={mapRef}
      {...otherProps}
    >
      {children}
      {isShowControls && <NavigationControl showCompass showZoom position="bottom-left" />}
    </ReactMapGL>
  );
};

Map.propTypes = {
  isShowControls: PropTypes.bool,
  children: PropTypes.node,
  mapRef: PropTypes.object,
};

export default Map;
