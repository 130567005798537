import React from 'react';
import Box from '@mui/material/Box';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Fab from '@mui/material/Fab/Fab';
import * as PropTypes from 'prop-types';

import UserAvatar from '../UserAvatar';
import styles from './styles';

const MarketplaceViewUserButton = (props) => {
  const classes = styles();
  const {
    isOpenUserSidebar,
    onClick,
    userAvatar: { fetching, userName, url },
    leftPosition = 0,
    bottomPosition = '100%',
  } = props;
  const rotationStyles = isOpenUserSidebar
    ? {
        transform: 'rotate(180deg) translate(-10%, 15%)',
      }
    : {};
  const transitionStyles = {
    transition: 'all .5s',
  };

  return (
    <Box
      position="absolute"
      bottom={bottomPosition}
      left={leftPosition}
      alignItems="center"
      p={0.25}
      display={{
        xs: 'flex',
        md: 'none',
      }}
      zIndex={1099}
      style={transitionStyles}
    >
      <Box
        style={{
          ...rotationStyles,
          ...transitionStyles,
        }}
      >
        <NavigateBeforeIcon className={classes.icon} color="primary" />
      </Box>
      <Fab size="medium" color="primary" onClick={onClick} className={classes.button}>
        <UserAvatar className={classes.smallAvatar} isLoading={fetching} name={userName} src={url} />
      </Fab>
    </Box>
  );
};

MarketplaceViewUserButton.propTypes = {
  userAvatar: PropTypes.shape({
    userName: PropTypes.string,
    fetching: PropTypes.string,
    url: PropTypes.string,
  }),
  isOpenUserSidebar: PropTypes.bool,
  onClick: PropTypes.func,
  leftPosition: PropTypes.number,
  bottomPosition: PropTypes.string,
};

export default MarketplaceViewUserButton;
