import React from 'react';
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import ServiceIcon from '@uptime/shared/components/Icons/ServiceIcon';
import { ALL_WEEK_DAYS } from '@uptime/shared/constants';
import EmptyPlaceholder from '@uptime/shared/components/Placeholders/EmptyPlaceholder';

import styles from '@uptime/shared/screens/Marketplace/View/styles';
import AvailabilityDay from './AvailabilityDay';
import AvailabilitySkeleton from './AvailabilitySkeleton';

const Availability = (props) => {
  const classes = styles();
  const { services, isLoading } = props;

  if (isLoading) return <AvailabilitySkeleton />;

  const renderServices = (
    <Box
      position="absolute"
      width="100%"
      height="100%"
      overflow="auto"
      boxSizing="border-box"
      top={0}
      left={0}
      p={3}
      pl={{
        xs: 3,
        md: 0,
      }}
    >
      {ALL_WEEK_DAYS.map(({ short, label }) => (
        <AvailabilityDay key={short} day={short} label={label} services={services} />
      ))}
    </Box>
  );

  const renderContent =
    !isLoading && isEmpty(services) ? (
      <Box display="flex" alignItems="center" height="100%" className={classes.bgGrey}>
        <EmptyPlaceholder>
          <ServiceIcon />
        </EmptyPlaceholder>
      </Box>
    ) : (
      renderServices
    );

  return (
    <Box flex="1" display="flex" flexDirection="column" position="relative" p={3}>
      {renderContent}
    </Box>
  );
};

Availability.propTypes = {
  services: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default Availability;
