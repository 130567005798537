import { Children, PureComponent, createElement } from 'react';
import SuperCluster from 'supercluster';
import { MapContext } from '@urbica/react-map-gl';
import * as PropTypes from 'prop-types';

import { getMarkersFromCluster } from '@uptime/shared/utils/geo';
import { point, shallowCompareChildren } from './utils';

class CoreCluster extends PureComponent {
  _map;

  _cluster;

  static displayName = 'Cluster';

  static defaultProps = {
    minZoom: 0,
    maxZoom: 16,
    radius: 40,
    extent: 512,
    nodeSize: 64,
  };

  static propTypes = {
    minZoom: PropTypes.number,
    maxZoom: PropTypes.number,
    radius: PropTypes.number,
    extent: PropTypes.number,
    nodeSize: PropTypes.number,
    component: PropTypes.func,
    children: PropTypes.node,
  };

  constructor(props) {
    super(props);

    this.state = {
      clusters: [],
    };
  }

  componentDidMount() {
    this._createCluster(this.props);
    this._recalculate();

    this._map.on('moveend', this._recalculate);
  }

  componentDidUpdate(prevProps) {
    const shouldUpdate =
      prevProps.minZoom !== this.props.minZoom ||
      prevProps.maxZoom !== this.props.maxZoom ||
      prevProps.radius !== this.props.radius ||
      prevProps.extent !== this.props.extent ||
      prevProps.nodeSize !== this.props.nodeSize ||
      !shallowCompareChildren(prevProps.children, this.props.children);

    if (shouldUpdate) {
      this._createCluster(this.props);
      this._recalculate();
    }
  }

  componentWillUnmount() {
    if (!this._map || !this._map.getStyle()) {
      return;
    }

    this._map.off('moveend', this._recalculate);
  }

  _createCluster = (props) => {
    const { minZoom, maxZoom, radius, extent, nodeSize, children } = props;

    const cluster = new SuperCluster({
      minZoom,
      maxZoom,
      radius,
      extent,
      nodeSize,
      reduce: (accumulated, props) => {
        accumulated.items = getMarkersFromCluster(accumulated, props);
      },
      map: (props) => props,
    });

    const points = Children.map(children, (child) =>
      point([child.props.longitude, child.props.latitude], child)
    );

    cluster.load(points);
    this._cluster = cluster;
  };

  _recalculate = () => {
    const zoom = this._map.getZoom();
    const bounds = this._map.getBounds().toArray();
    const box = bounds[0].concat(bounds[1]);

    const clusters = this._cluster.getClusters(box, Math.round(zoom));
    this.setState(() => ({ clusters }));
  };

  _renderCluster = (cluster) => {
    const [longitude, latitude] = cluster.geometry.coordinates;
    const {
      cluster_id: clusterId,
      point_count: pointCount,
      point_count_abbreviated: pointCountAbbreviated,
      items,
    } = cluster.properties;

    return createElement(this.props.component, {
      longitude,
      latitude,
      clusterId,
      pointCount,
      pointCountAbbreviated,
      items,
      key: `cluster-${cluster.properties.cluster_id}`,
    });
  };

  render() {
    return createElement(MapContext.Consumer, {}, (map) => {
      if (map) {
        this._map = map;
      }

      if (this.state.clusters.length === 0) {
        return null;
      }

      return this.state.clusters.map((cluster) => {
        if (cluster.properties.cluster) {
          return this._renderCluster(cluster);
        }
        const { type, key, props } = cluster.properties;
        return createElement(type, { key, ...props });
      });
    });
  }
}

export default CoreCluster;
