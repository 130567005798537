export const getPositionConfig = (position, classes) => {
  switch (position) {
    case 'top':
      return {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        arrowClass: classes.top,
        popoverClass: classes.topPopover,
      };
    case 'left':
      return {
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'left',
        },
        arrowClass: classes.left,
        popoverClass: classes.leftPopover,
      };
    case 'bottom':
      return {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        arrowClass: classes.bottom,
        popoverClass: classes.bottomPopover,
      };
    default:
      return {
        anchorOrigin: {
          vertical: 'center',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'center',
          horizontal: 'right',
        },
        arrowClass: classes.right,
        popoverClass: classes.rightPopover,
      };
  }
};

export const serializeSearchParams = (searchParams) =>
  searchParams ? encodeURIComponent(JSON.stringify(searchParams)) : '';

export const getLatest5Count = (count) => (count > 5 ? 5 : count);
