import React, { useState, useEffect, useRef, createRef, memo } from 'react';
import Box from '@mui/material/Box';
import uuid from 'uuid';
import * as PropTypes from 'prop-types';
import { Marker } from '@urbica/react-map-gl';
import useThunkReducer from 'use-thunk-reducer';
import trim from 'lodash/trim';
import { usePrevious } from '@uptime/shared/hooks';

import { doFetchAddressOptions, getLocationById, getPolygon } from '@uptime/shared/utils/facilities';
import Map from '@uptime/shared/components/Map';
import { comparePropsForItemsOnMap, getMapParams, milesToPixelsAtZoom } from '@uptime/shared/utils/geo';
import Search from '@uptime/shared/components/Search';
import BarberPole from '@uptime/shared/components/Progress/BarberPole';
import { SEARCH_DELAY } from '@uptime/shared/constants';
import styles from './styles';
import ItemMarker from './Marker/index';
import CoreCluster from './Cluster/Cluster';
import ClusterMarker from './Cluster';
import { getInitialState, ItemsOnMapReducer } from './store/reducer';
import {
  setAddressOptions,
  setLoading,
  setPolygon,
  setRequestTimer,
  setSearch,
  setSearchingByAddress,
  setSearchTimer,
} from './store/actions';

const REQUEST_DELAY = 500;

const ItemsOnMap = (props) => {
  const classes = styles();

  const {
    area: { range, ...area } = {},
    items,
    isDisabledSearch,
    isShowTitle,
    onFetch,
    center,
    viewComponent: View,
    hasCompliance,
  } = props;

  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 8,
  });

  const initialState = getInitialState();
  const reducer = ItemsOnMapReducer(initialState);
  const [state, dispatch] = useThunkReducer(reducer, initialState);

  const { polygon, addressOptions, search, searchTimer, requestTimer, isSearchingByAddress, isLoading } =
    state;

  useEffect(() => {
    setViewport((state) => ({
      ...state,
      ...center,
    }));
  }, [center]); // eslint-disable-lin

  const mapRef = useRef();
  const blockRef = createRef();

  const reDrawMap = () => mapRef?.current?.getMap()?.resize();

  useEffect(() => {
    reDrawMap();
  }, [blockRef]);

  useEffect(() => {
    const container = document.getElementById('map-container');
    new ResizeObserver(reDrawMap).observe(container);
  }, []);

  const internalPolygon = usePrevious(polygon);
  const mountedViewport = usePrevious(viewport);

  const { zoom, center: detectedCenter } = getMapParams(items, mapRef);

  const fetchMoreMarkersForPolygon = () => {
    if (isSearchingByAddress && mountedViewport) {
      const newPolygon = getPolygon(mapRef);
      dispatch(setPolygon(newPolygon));
      onFetch({ polygon: newPolygon, internalPolygon });
    }
  };

  useEffect(() => {
    requestTimer && clearTimeout(requestTimer);
    const newTimer = setTimeout(fetchMoreMarkersForPolygon, REQUEST_DELAY);
    dispatch(setRequestTimer(newTimer));

    return () => clearTimeout(newTimer);
  }, [viewport]); // eslint-disable-line

  useEffect(() => {
    if (!isSearchingByAddress && !isLoading && detectedCenter && zoom !== undefined) {
      setViewport((state) => ({
        ...state,
        ...detectedCenter,
        zoom,
      }));
    }
  }, [items, isLoading]); // eslint-disable-line

  const diameter = range ? milesToPixelsAtZoom(range, area.latitude, viewport.zoom) : 0;

  const handleSearch = ({ search }) => {
    dispatch(setSearch(search));
    searchTimer && clearTimeout(searchTimer);
    const trimmedSearch = trim(search);

    if (isSearchingByAddress)
      return (
        search && doFetchAddressOptions(trimmedSearch, (options) => dispatch(setAddressOptions(options)))
      );

    const timer = setTimeout(async () => {
      try {
        dispatch(setLoading(true));
        await onFetch({ search: trimmedSearch });
      } finally {
        dispatch(setLoading(false));
      }
    }, SEARCH_DELAY);

    return dispatch(setSearchTimer(timer));
  };

  const handleOptionClick = (addressId) => {
    const location = getLocationById(addressOptions, addressId);
    const [longitude, latitude] = location.center;

    setViewport((state) => ({ ...state, longitude, latitude }));
    dispatch(setSearch(location.label));
  };

  const handleSearchReset = () => dispatch(setSearch(null));

  const handleSwitchSearchCriteria = (isSwitched) => {
    if (isSwitched !== isSearchingByAddress) {
      handleSearchReset();
      dispatch(setPolygon(undefined));
    }
    dispatch(setSearchingByAddress(isSwitched));
  };

  const searchingPlaceholder = `Search by ${isSearchingByAddress ? 'Address' : 'Name'}...`;

  return (
    <Box
      id="map-container"
      width="100%"
      height="100%"
      position="relative"
      className={classes.rounded}
      ref={blockRef}
    >
      {!isDisabledSearch && (
        <Box position="absolute" top={16} left="0" width="100%">
          <Box pl={2} pr={2}>
            <Search
              isOnChange
              onSubmit={handleSearch}
              onOptionClick={handleOptionClick}
              onReset={handleSearchReset}
              options={addressOptions}
              initialValues={{ search }}
              className={classes.searchShadow}
              placeholder={searchingPlaceholder}
              onSwitch={handleSwitchSearchCriteria}
              isDisabledTrimming
            />
          </Box>
        </Box>
      )}
      {!isSearchingByAddress && isLoading && (
        <Box
          position="absolute"
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          zIndex={1}
          className={classes.loading}
        >
          <BarberPole />
        </Box>
      )}
      <Map
        onViewportChange={(newViewport) => setViewport(newViewport)}
        mapRef={mapRef}
        isShowControls
        {...viewport}
      >
        {range && (
          <Marker {...area}>
            <Box height={diameter} width={diameter} className={classes.point} borderRadius="50%" />
          </Marker>
        )}
        <CoreCluster radius={40} extent={512} nodeSize={64} component={ClusterMarker}>
          {items.map((item) => (
            <Marker longitude={item.longitude} latitude={item.latitude} key={uuid.v4()}>
              <ItemMarker
                item={item}
                view={<View {...item} hasCompliance={hasCompliance} />}
                isShowTitle={isShowTitle}
              />
            </Marker>
          ))}
        </CoreCluster>
      </Map>
    </Box>
  );
};

ItemsOnMap.propTypes = {
  center: PropTypes.shape({
    longitude: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      longitude: PropTypes.number.isRequired,
      latitude: PropTypes.number.isRequired,
      actions: PropTypes.node.isRequired,
      photo: PropTypes.node,
    })
  ).isRequired,
  onFetch: PropTypes.func.isRequired,
  viewComponent: PropTypes.any.isRequired,
  area: PropTypes.object,
  isDisabledSearch: PropTypes.bool,
  isShowTitle: PropTypes.bool,
  hasCompliance: PropTypes.bool,
};

export default memo(ItemsOnMap, comparePropsForItemsOnMap);
