import React from 'react';
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { getFontHue } from '@uptime/shared/utils/general';
import { getDayChart } from '@uptime/shared/utils/marketplace';

import styles from '@uptime/shared/screens/Marketplace/View/styles';
import Slot from './Slot';

const AvailabilityDay = (props) => {
  const classes = styles();
  const { label, day, services = [] } = props;
  const { caption, slots } = getDayChart(services, day);

  const slotWidth = `${100 / slots.length}%`;

  return (
    <Box p={1} pl={0.5} pr={0.5} mb={0.5} display="flex" className={classes.dayContainer}>
      <Slot title={label} caption={caption} isFixedWidth />
      <Box width="100%" height="100%" display="flex" overflow="auto" boxSizing="border-box">
        {slots.length ? (
          slots.map(({ label, caption, color }, index) => {
            const slotClasses = classNames(classes.timeSlot, classes[getFontHue(color)]);

            return (
              <Slot
                className={slotClasses}
                key={index}
                width={slotWidth}
                title={label}
                caption={caption}
                color={color}
              />
            );
          })
        ) : (
          <Box className={classes.empty}>Not Available</Box>
        )}
      </Box>
    </Box>
  );
};

AvailabilityDay.propTypes = {
  day: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  services: PropTypes.array,
};

export default AvailabilityDay;
