import React from 'react';
import * as PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
};

const MyInputComponent = React.forwardRef((props, ref) => {
  const { component: Component, ...other } = props;

  return <div ref={ref} {...other} />;
});

const Control = ({ children, innerProps, innerRef, selectProps: { classes, TextFieldProps } }) => (
  <TextField
    InputProps={{
      inputComponent: MyInputComponent,
      inputProps: {
        className: classes.input,
        ref: innerRef,
        children,
        ...innerProps,
      },
    }}
    fullWidth
    {...TextFieldProps}
  />
);

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  selectProps: PropTypes.object.isRequired,
};

export default Control;
