import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu/Menu';
import PropTypes from 'prop-types';

const PageActions = (props) => {
  const { isModalPopupShouldBeClosed = false } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const { children } = props;

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  useEffect(() => {
    handleClose();
  }, [isModalPopupShouldBeClosed]);

  return (
    <>
      <IconButton onClick={handleClick} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        transitionDuration={500}
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {children}
      </Menu>
    </>
  );
};

PageActions.propTypes = {
  children: PropTypes.node.isRequired,
  isModalPopupShouldBeClosed: PropTypes.bool,
};

export default PageActions;
