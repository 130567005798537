import { InputBase } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { errorStyle, inputStyle } from '@uptime/shared/styles/FormElementStyles';

export const CustomInput = withStyles((theme) => ({
  root: {
    ...inputStyle(theme),
    padding: theme.spacing(0.25, 2),
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: 'relative',
    border: 'none !important',
    padding: '6px 0 7px !important',
    boxSizing: 'content-box !important',
    '&::placeholder': {
      color: theme.palette.grey[600],
    },
    '&:focus': {
      color: theme.palette.grey.black,
    },
    '&[type="number"]': {
      lineHeight: '29px',
    },
  },
  error: {
    color: theme.palette.error.main,
    ...errorStyle(theme),
  },
}))(InputBase);
