import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: 6,
    backgroundColor: theme.palette.secondary.light,
    border: '2px solid transparent',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '& .MuiBox-root': {
      margin: '0 !important',
    },
    '&:focus-within': {
      boxShadow: `0 0 0 4px ${theme.palette.primary.main}1f`,
      borderColor: theme.palette.primary.main,
      '& .MuiInputBase-root': {
        boxShadow: 'none !important',
        borderColor: 'transparent !important',
      },
    },
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.grey[600],
  },
  form: {
    width: '100%',
  },
  hide: {
    display: 'none',
  },
  lowHeight: {
    height: '36px',
  },
}));
