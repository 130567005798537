import React from 'react';
import uuid from 'uuid';
import * as PropTypes from 'prop-types';
import { FormControl, InputLabel, Box } from '@mui/material';
import classNames from 'classnames';
import isObject from 'lodash/isObject';

import DropDown from '@uptime/shared/components/DropDown';
import { useStyles } from '@uptime/shared/styles/FormElementStyles';
import { CustomInput } from './styles';

const Input = (props) => {
  const {
    input: { onChange, ...inputProps },
    label,
    meta = {},
    options,
    onOptionClick,
    onChangeCustom,
    dropDownStyles,
    formControlClass,
    fullSpace = false,
    resetMargin,
    testid,
    ...other
  } = props;

  const elementId = uuid.v4();
  const classes = useStyles();
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;
  const handleChange = (event) => {
    onChange(isObject(event) ? event.target.value || null : event);
    onChangeCustom && onChangeCustom(event);
  };
  const errorClasses = classNames({
    [classes.error]: true,
    error: true,
  });

  return (
    <Box m={resetMargin ? 0 : 2} {...(fullSpace && { mx: 0 })}>
      <FormControl variant="filled" className={formControlClass} fullWidth data-testid={testid}>
        {label && (
          <InputLabel htmlFor={elementId} shrink error={showError} className={classes.label}>
            {label}
          </InputLabel>
        )}
        <CustomInput
          id={elementId}
          error={showError}
          variant="outlined"
          {...inputProps}
          {...other}
          onChange={handleChange}
          fullWidth
        />

        <DropDown
          items={options}
          onOptionClick={(value) => onOptionClick(value)}
          dropDownStyles={dropDownStyles}
        />

        {showError && (
          <div className={errorClasses} data-testid="validationError">
            {meta.error || meta.submitError}
          </div>
        )}
      </FormControl>
    </Box>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  onOptionClick: PropTypes.func,
  onChangeCustom: PropTypes.func,
  dropDownStyles: PropTypes.object,
  formControlClass: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
    touched: PropTypes.bool,
  }),
  fullSpace: PropTypes.bool,
  resetMargin: PropTypes.bool,
};

Input.defaultProps = {
  label: '',
  disabled: false,
  options: [],
};

export default Input;
