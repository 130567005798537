import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  form: {
    maxWidth: 544,
    height: 428,
    marginTop: theme.spacing(4),
    overflow: 'initial',
    padding: `${theme.spacing(4)} 0px 0px`,
  },
  titleWrapper: {
    padding: `${theme.spacing(0)} ${theme.spacing(4)}`,
    boxSizing: 'border-box',
    marginBottom: theme.spacing(4),
  },
  title: {
    fontWeight: 400,
    fontSize: 16,
    color: theme.palette.grey.black,
  },
}));
