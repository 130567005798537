import { useQuery } from '@apollo/client';

import { getServiceTypes } from '../graphql/marketplace';
import { getAllEquipmentCategories } from '../graphql/devices';

import { devicesClient, marketplaceClient as client } from '../graphql/apolloClientConfiguration';

export default (userId = null) => {
  const { loading, data: { serviceTypes } = {} } = useQuery(getServiceTypes, {
    variables: {
      userId,
    },
    client,
  });

  const { loading: categoriesAreLoading, data: { categories } = {} } = useQuery(getAllEquipmentCategories, {
    variables: {
      userId,
    },
    client: devicesClient,
  });

  return {
    loading,
    categoriesAreLoading,
    serviceTypes,
    categories,
  };
};
