import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
  visibleDropDownContent: {
    display: 'block',
  },
  invisibleDropDownContent: {
    display: 'none',
  },
  dropDownContent: {
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    top: '75px',
    padding: `${theme.spacing(2)} ${theme.spacing(0)}`,
    borderRadius: 0,
    zIndex: 99,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
    '& div': {
      padding: theme.spacing(1),
      cursor: 'pointer',
      borderRadius: 6,
      '&:hover': {
        backgroundColor: theme.palette.primary.light1,
      },
    },
  },
  '.dropDownContent > div': {
    border: '1px solid black',
  },
}));
