import { gql } from '@apollo/client';

export const getAccountProfile = gql`
  query getProfile($userId: Int!) {
    profile: getProfile(userId: $userId) {
      userId
      firstName
      lastName
      mobile
      image
      businessName
      businessEmail
      businessPhone
      businessImage
      linkedIn
      description
      companyTypeId
      step
      companySite
      lastLogin
      customerType {
        id
        alias
        title
      }
      baseUser {
        email
        profile {
          firstName
          lastName
        }
      }
      companyType {
        id
        title
      }
      topLevelEmail
      topLevelFirstName
      topLevelLastName
    }
  }
`;

export const getBusinessCards = gql`
  query businessCards($userIds: [Int!]!) {
    cards: businessCards(userIds: $userIds) {
      userId
      firstName
      lastName
      mobile
      businessName
      businessEmail
      businessPhone
      linkedIn
      description
      companySite
    }
  }
`;

export const getUserProfile = gql`
  query getProfile($userId: Int!) {
    userData: getProfile(userId: $userId) {
      userId
      firstName
      lastName
      mobile
      businessEmail
      businessName
      businessPhone
      companySite
      customerType {
        id
        alias
        title
      }
      baseUser {
        email
      }
      userGroupId
    }
  }
`;

export const getContact = gql`
  query fetchProfile($userId: Int!) {
    profile: getProfile(userId: $userId) {
      userId
      firstName
      lastName
      baseUser {
        email
      }
    }
  }
`;

export const getUserAddress = gql`
  query getUserAddress($userId: Int!, $isBusiness: Boolean!) {
    address: getUserAddress(userId: $userId, isBusiness: $isBusiness) {
      id
      userId
      isBusiness
      addressLine
      addressLineTwo
      city
      state
      zipCode
    }
  }
`;

export const getSubAccount = gql`
  query getSubAccount($id: Int!) {
    contact: getSubAccount(id: $id) {
      username
      email
      profile {
        firstName
        lastName
        mobile
      }
    }
  }
`;

export const getSubAccounts = gql`
  query getSubAccounts($userId: Int!, $sortBy: [SubAccountSortInput!]) {
    subAccounts: getSubAccounts(userId: $userId, sortBy: $sortBy) {
      username
      email
      lastLogin
      teamTitle
      userId
      image
      firstName
      lastName
      mobile
    }
  }
`;

export const getUserSettings = gql`
  query getUserSettings($userId: Int!) {
    settings: getUserSettings(userId: $userId) {
      userId
      settingId
      isActive
      alias
      title
      position
      type
      value
    }
  }
`;

export const getUserDashboards = gql`
  {
    getUserDashboards {
      id
      title
    }
  }
`;

export const getUserInvoiceDetails = gql`
  query getUserInvoiceDetails($userId: Int!) {
    invoice: getUserInvoiceDetails(userId: $userId) {
      userId
      addressLine
      addressLineTwo
      city
      state
      zipCode
      attention
      isBusinessAddress
    }
  }
`;

export const getUserStripeAccount = gql`
  query userStripeAccount($userId: Int!) {
    stripeAccount: userStripeAccount(userId: $userId) {
      accountId
    }
  }
`;

export const getAccountGroups = gql`
  query accountGroups {
    accountGroups {
      groupId
      name
    }
  }
`;

export const getUserSystemSetting = gql`
  query getUserSystemSetting($userId: Int!, $alias: String!) {
    systemSettings: getUserSystemSetting(userId: $userId, alias: $alias) {
      userId
      settingId
      position
      title
      isActive
      type
      value
    }
  }
`;

export const getCompanyTypes = gql`
  query getCompanyTypes($userId: Int!) {
    companyTypes: getCompanyTypes(userId: $userId) {
      value: id
      label: title
    }
  }
`;

export const getCustomerTypes = gql`
  query getCustomerTypes($userId: Int!) {
    getCustomerTypes(userId: $userId) {
      value: id
      label: title
      alias
    }
  }
`;

// Mutations

export const updateUserSystemSetting = gql`
  mutation updateUserSystemSetting($userId: Int!, $setting: UserSystemSettingInput!) {
    updateUserSystemSetting(userId: $userId, setting: $setting)
  }
`;

export const whetherUserExists = gql`
  query whetherUserExists($email: String!) {
    isUserExists: whetherUserExists(email: $email)
  }
`;

export const onUpdateProfile = gql`
  mutation updateProfile($userId: Int!, $profileData: UpdateProfileInput!) {
    updateProfile(userId: $userId, profileData: $profileData) {
      userId
      firstName
      lastName
      mobile
      image
      businessName
      businessEmail
      businessPhone
      businessImage
      companyTypeId
      linkedIn
      description
      step
      baseUser {
        email
      }
      companyType {
        id
        title
      }
    }
  }
`;

export const onUpdateAddress = gql`
  mutation updateUserAddress(
    $userId: Int!
    $userAddressData: UpdateUserAddressInput!
    $profileData: UpdateProfileInput!
  ) {
    updateUserAddress(userAddressData: $userAddressData)
    updateProfile(userId: $userId, profileData: $profileData) {
      userId
      businessName
      businessEmail
      businessPhone
      businessImage
      companyTypeId
      customerType {
        id
        title
        alias
      }
      linkedIn
      description
      companyType {
        id
        title
      }
    }
  }
`;

export const onCreateUserAddress = gql`
  mutation createUserAddress(
    $userId: Int!
    $userAddressData: CreateUserAddressInput!
    $profileData: UpdateProfileInput!
  ) {
    createUserAddress(userId: $userId, userAddressData: $userAddressData) {
      id
      userId
      isBusiness
      addressLine
    }
    updateProfile(userId: $userId, profileData: $profileData) {
      userId
      businessName
      businessEmail
      businessPhone
      businessImage
      companyTypeId
      linkedIn
      description
      companyType {
        id
        title
      }
    }
  }
`;

export const onUpdateUserSettings = gql`
  mutation updateUserSettings($userId: Int!, $settings: [UserSettingInput]!) {
    updateUserSettings(userId: $userId, settings: $settings)
  }
`;

export const onSaveUserInvoice = gql`
  mutation saveUserInvoiceDetails($userId: Int!, $invoiceData: UserInvoiceDetailsInput!) {
    saveUserInvoiceDetails(userId: $userId, invoiceData: $invoiceData)
  }
`;

export const onCreateSubAccount = gql`
  mutation createSubAccount($email: String!, $firstName: String!, $lastName: String!, $teamId: Int!) {
    createSubAccount(email: $email, firstName: $firstName, lastName: $lastName, teamId: $teamId)
  }
`;

export const onSaveStripeAccountData = gql`
  mutation saveStripeAccountData($userId: Int!, $payload: stripeOauthCallbackPayload!) {
    saveStripeAccountData(userId: $userId, payload: $payload)
  }
`;

export const getImpersonatedToken = gql`
  query getImpersonatedToken($signature: String!, $identificator: String!) {
    token: getImpersonatedToken(signature: $signature, identificator: $identificator)
  }
`;

export const signInAsUnifiedImagingUser = gql`
  mutation signInAsUnifiedImagingUser($token: String!) {
    token: signInAsUnifiedImagingUser(token: $token)
  }
`;

export const getTechnicianTeam = gql`
  query getTechnicianTeam(
    $pagination: PaginationInput
    $sortBy: [TechnicianTeamSortInput!]
    $filter: TechnicianTeamFilterInput!
    $searchBy: String
  ) {
    team: getTechnicianTeam(pagination: $pagination, sortBy: $sortBy, filter: $filter, searchBy: $searchBy) {
      hits {
        id
        fullName
        email
        status
        type
        userId
        signature
        createdAt
        phone
        companyName
        teamTitle
      }
      pageCount
      currentPage
      itemsCount
    }
  }
`;

export const createTechnicianSubAccount = gql`
  mutation createTechnicianSubAccount(
    $email: String!
    $firstName: String!
    $lastName: String!
    $teamId: Int!
  ) {
    createTechnicianSubAccount(email: $email, firstName: $firstName, lastName: $lastName, teamId: $teamId)
  }
`;

export const getRoles = gql`
  query getRoles {
    roles {
      id
      title
      alias
    }
  }
`;

export const removeContractorFromTechnicianTeam = gql`
  mutation removeContractorFromTechnicianTeam($id: String!) {
    removeContractorFromTechnicianTeam(id: $id)
  }
`;

export const getTechnicianTeamMember = gql`
  query getTechnicianTeamMember($userId: Int!, $type: String!) {
    technicianTeamMember: getTechnicianTeamMember(userId: $userId, type: $type) {
      id
      status
      role
    }
  }
`;

export const inviteContractorToTechnicianTeam = gql`
  mutation inviteContractorToTechnicianTeam($userId: Int!) {
    inviteContractorToTechnicianTeam(userId: $userId)
  }
`;

export const acceptContractorInviteToTechnicianTeam = gql`
  mutation acceptContractorInviteToTechnicianTeam($token: String!) {
    acceptContractorInviteToTechnicianTeam(token: $token)
  }
`;

export const declineContractorInviteToTechnicianTeam = gql`
  mutation declineContractorInviteToTechnicianTeam($token: String!) {
    declineContractorInviteToTechnicianTeam(token: $token)
  }
`;

export const resendContractorInviteToTechnicianTeam = gql`
  mutation resendContractorInviteToTechnicianTeam($id: String!) {
    resendContractorInviteToTechnicianTeam(id: $id)
  }
`;

export const setLastLogin = gql`
  mutation setLastLogin($userId: Int!) {
    setLastLogin(userId: $userId)
  }
`;

export const getStaffTeams = gql`
  query getStaffTeams($accountId: Int!) {
    getStaffTeams(accountId: $accountId) {
      id
      alias
      title
    }
  }
`;
