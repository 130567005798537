import React, { useEffect, useRef, useState } from 'react';
import { Marker } from '@urbica/react-map-gl';
import * as PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';

import { getMappedCoverages } from '@uptime/shared/utils/marketplace';
import { getTechnicianCoverages } from '@uptime/shared/graphql/marketplace';
import Map from '@uptime/shared/components/Map';
import PinIcon from '@uptime/shared/components/Map/PinIcon';
import { getMapParams, milesToPixelsAtZoom } from '@uptime/shared/utils/geo';
import AddressIcon from '@uptime/shared/components/Icons/AddressIcon';
import styles from '@uptime/shared/components/Coverage/styles';
import EmptyPlaceholder from '@uptime/shared/components/Placeholders/EmptyPlaceholder';

import { marketplaceClient as client } from '@uptime/controller/src/apolloClientConfiguration';
import marketplaceStyles from '@uptime/shared/screens/Marketplace/View/styles';
import { MIN_RADIUS } from '@uptime/shared/constants/marketplace';

const Coverage = (props) => {
  const classes = styles();
  const marketplaceClasses = marketplaceStyles();
  const { userId } = props;
  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 8,
  });

  const mapRef = useRef();

  const { loading, data: { coverages } = {} } = useQuery(getTechnicianCoverages, {
    variables: {
      userId,
    },
    client,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (!coverages) return;

    const mappedCoverages = getMappedCoverages(coverages);

    const { zoom, center } = getMapParams(mappedCoverages, mapRef);

    if (center && zoom !== undefined) {
      setViewport((state) => ({
        ...state,
        ...center,
        zoom,
      }));
    }
  }, [coverages]);

  const renderContent =
    !loading && coverages ? (
      <Map
        className={classes.map}
        onViewportChange={setViewport}
        isShowControls
        {...viewport}
        mapRef={mapRef}
      >
        {coverages.map(({ coordinates: { latitude, longitude }, distance, id }) => {
          const radiusInPixels = milesToPixelsAtZoom(distance, latitude, viewport.zoom);

          return (
            <Marker latitude={latitude} longitude={longitude} key={id}>
              {radiusInPixels < MIN_RADIUS ? (
                <PinIcon />
              ) : (
                <Box
                  height={radiusInPixels * 2}
                  width={radiusInPixels * 2}
                  className={classes.point}
                  borderRadius="50%"
                />
              )}
            </Marker>
          );
        })}
      </Map>
    ) : (
      <Box display="flex" alignItems="center" height="100%" className={classes.bgGrey}>
        <EmptyPlaceholder>
          <AddressIcon />
        </EmptyPlaceholder>
      </Box>
    );

  return (
    <Box className={marketplaceClasses.coverages}>{loading ? <Box className="loader" /> : renderContent}</Box>
  );
};

Coverage.propTypes = {
  userId: PropTypes.number.isRequired,
};

export default Coverage;
