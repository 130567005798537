import React from 'react';
import Grid from '@mui/material/Grid';

import InputSkeleton from '../../../components/Skeleton/InputSkeleton';

const FiltersSkeleton = () => (
  <Grid container>
    <Grid item xs={12} lg={6}>
      <InputSkeleton />
    </Grid>
    <Grid item xs={12} lg={6}>
      <InputSkeleton />
    </Grid>
    <Grid item xs={12} lg={4}>
      <InputSkeleton />
    </Grid>
    <Grid item xs={12} lg={4}>
      <InputSkeleton />
    </Grid>
    <Grid item xs={12} lg={4}>
      <InputSkeleton />
    </Grid>
  </Grid>
);

export default FiltersSkeleton;
