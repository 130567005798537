import React from 'react';
import { Typography } from '@mui/material';
import * as PropTypes from 'prop-types';

const SingleValue = (props) => (
  <Typography
    className={props.selectProps.classes.singleValue}
    {...props.innerProps}
    title={props.children}
    data-testid={`dropDownValue_${props.children}`}
  >
    {props.children}
  </Typography>
);

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.any,
  selectProps: PropTypes.object.isRequired,
};

export default SingleValue;
