import React, { useEffect, useRef } from 'react';
import uuid from 'uuid';
import Select from 'react-select';
import AsyncPaginate from './AsyncPaginate';
import * as PropTypes from 'prop-types';
import { FormControl, InputLabel, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import { isMobile } from 'react-device-detect';

import { useStyles } from '@uptime/shared/styles/FormElementStyles';
import { getMutatedType } from '@uptime/shared/utils/general';
import * as components from './components';

const SELECT_ALL_VALUE = -1;

const AutoCompleteInput = ({
  input: { value, onChange, ...inputOther },
  className,
  label,
  labelIcon,
  allOptionText,
  meta,
  options,
  onChangeCustom,
  isCheckbox,
  shouldBeNativeOnMobile,
  testid,
  async,
  loadOptions,
  defaultAsyncOptions,
  ...other
}) => {
  const elementId = uuid.v4();
  const theme = useTheme();
  const autoCompleteStyles = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 1301,
      borderRadius: 0,
    }),
    menu: (base) => ({
      ...base,
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: 6,
      overflow: 'hidden',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    }),
    clearIndicator: (base) => ({
      ...base,
      cursor: 'pointer',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      cursor: 'pointer',
      color: theme.palette.grey[600],
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: 14,
      color: theme.palette.grey[600],
    }),
    multiValue: (base) => ({
      ...base,
      borderRadius: 3,
    }),
  };
  const classes = useStyles();
  const previousValuesRef = useRef([]);
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

  const selectClasses = classNames({
    [classes.select]: true,
    [classes.selectWithLabel]: Boolean(label),
    [classes.error]: showError,
    [classes.selectWithCount]: isArray(value),
    [classes.disabled]: Boolean(other.isDisabled),
  });

  const isNative = shouldBeNativeOnMobile && isMobile;

  const handleChange = (event) => {
    if (isNative) {
      if (other.isMulti) {
        const newValue = Array.from(event.target.selectedOptions, ({ value }) => getMutatedType(value));
        onChange(newValue);

        const customValues = options?.filter((item) => newValue.indexOf(item.value) > -1) || [];

        return onChangeCustom && onChangeCustom(customValues);
      }

      const newValue = getMutatedType(event.target.value);

      onChange(newValue === other?.placeholder ? null : newValue);

      return onChangeCustom && onChangeCustom(options?.find((item) => item.value === newValue) || null);
    }

    const values = event && (other.isMulti ? event.map((e) => e.value) : event.value);
    if (Boolean(allOptionText) && values) {
      let newValues = [];
      if (!values.includes(SELECT_ALL_VALUE) && previousValuesRef.current.includes(SELECT_ALL_VALUE)) {
        // 'All' option was unchecked => new values are an empty array
      } else if (
        values.includes(SELECT_ALL_VALUE) &&
        previousValuesRef.current.includes(SELECT_ALL_VALUE) &&
        values.length === options.length
      ) {
        // after all options were checked, 1 other was unchecked => uncheck 'All' option
        newValues = values.filter((val) => val !== SELECT_ALL_VALUE);
      } else if (
        (values.includes(SELECT_ALL_VALUE) && !previousValuesRef.current.includes(SELECT_ALL_VALUE)) ||
        (!values.includes(SELECT_ALL_VALUE) && values.length === options.length)
      ) {
        // either last available option was checked or 'All' option was checked
        const optionsValues = options?.map((o) => o.value) || [];
        newValues = [SELECT_ALL_VALUE, ...optionsValues];
      } else {
        newValues = values;
      }
      onChange(newValues);
      previousValuesRef.current = newValues;
    } else {
      onChange(values);
    }
    onChangeCustom && onChangeCustom(event);
  };

  const allOptions =
    Boolean(allOptionText) && options
      ? [{ value: SELECT_ALL_VALUE, label: allOptionText }, ...options]
      : options || [];
  const selectedValues =
    value !== undefined &&
    allOptions.filter((input) =>
      other.isMulti ? value.indexOf(input.value) > -1 : input.value === value || isEqual(value, input.value)
    );

  const conditionalValue = selectedValues ? selectedValues[0] : value;
  const optionValue = other.isMulti
    ? selectedValues
    : selectedValues === undefined
      ? undefined
      : conditionalValue;

  const selectInputRef = useRef();

  useEffect(() => {
    if (value === undefined || value === '') {
      selectInputRef?.current?.select?.clearValue();
      previousValuesRef.current = [];
    } else {
      previousValuesRef.current = value;
    }
  }, [value]);

  const isNotArrayForNative = value && !isArray(value);
  const nativeSelectValue = other?.isMulti && isNotArrayForNative ? [value] : value;

  return (
    <Box m={2} className={className}>
      <FormControl variant="filled" fullWidth data-testid={testid}>
        {label && (
          <InputLabel htmlFor={elementId} error={showError} className={classes.label} shrink>
            {label}
            {labelIcon && labelIcon}
          </InputLabel>
        )}
        {isNative ? (
          <Box pt={3}>
            <select
              inputId={elementId}
              className={classes.nativeSelect}
              value={nativeSelectValue}
              onChange={handleChange}
              multiple={other?.isMulti}
              disabled={Boolean(other.isDisabled)}
              {...inputOther}
              {...other}
            >
              {!other?.isMulti && <option value={null}>{other?.placeholder || ' '}</option>}
              {options &&
                options.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
            </select>
          </Box>
        ) : async ? (
          <AsyncPaginate
            loadOptions={loadOptions}
            ref={selectInputRef}
            classes={classes}
            inputId={elementId}
            className={selectClasses}
            error={showError}
            components={components}
            menuPortalTarget={document.body}
            styles={autoCompleteStyles}
            value={value}
            onChange={handleChange}
            menuPosition="fixed"
            blurInputOnSelect={!isCheckbox}
            closeMenuOnSelect={!isCheckbox}
            hideSelectedOptions={!isCheckbox}
            isCheckbox={isCheckbox}
            fullWidth
            placeholder
            {...inputOther}
            {...other}
          />
        ) : (
          <Select
            ref={selectInputRef}
            classes={classes}
            inputId={elementId}
            className={selectClasses}
            error={showError}
            components={components}
            menuPortalTarget={document.body}
            styles={autoCompleteStyles}
            options={allOptions}
            value={optionValue}
            onChange={handleChange}
            menuPosition="fixed"
            blurInputOnSelect={!isCheckbox}
            closeMenuOnSelect={!isCheckbox}
            hideSelectedOptions={!isCheckbox}
            isCheckbox={isCheckbox}
            captureMenuScroll={false}
            fullWidth
            placeholder
            {...inputOther}
            {...other}
          />
        )}
        {showError && (
          <div className={classes.error} data-testid="validationError">
            {meta.error || meta.submitError}
          </div>
        )}
      </FormControl>
    </Box>
  );
};

AutoCompleteInput.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitError: PropTypes.string,
    dirtySinceLastSubmit: PropTypes.bool,
    touched: PropTypes.bool,
  }).isRequired,
  options: PropTypes.array,
  allOptionText: PropTypes.string,
  onChangeCustom: PropTypes.func,
  isMulti: PropTypes.bool,
  shouldBeNativeOnMobile: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isCheckbox: PropTypes.bool,
  async: PropTypes.bool,
  loadOptions: PropTypes.func,
  defaultAsyncOptions: PropTypes.array,
};

AutoCompleteInput.defaultProps = {
  label: '',
  className: '',
  allOptionText: '',
  options: [],
  defaultAsyncOptions: [],
  onChangeCustom: undefined,
  isMulti: false,
  isSearchable: true,
  isClearable: false,
  isDisabled: false,
  isCheckbox: false,
  async: false,
};

export default AutoCompleteInput;
