import makeStyles from '@mui/styles/makeStyles';
import { LENGTH_FROM_CLUSTER_TO_MARKERS } from '@uptime/shared/constants';

export default makeStyles((theme) => ({
  rounded: {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    overflow: 'hidden',
    '& .mapboxgl-canvas:focus': {
      outline: 'none',
    },
  },
  searchShadow: {
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
    '& .customDropDown': {
      top: 55,
      left: -85,
    },
  },
  label: {
    color: theme.palette.grey[500],
  },
  loading: {
    backgroundColor: theme.palette.grey[300],
    transition: 'background-color 1s',
  },
  line: {
    strokeWidth: 2,
    stroke: theme.palette.primary.main,
  },
  vectorContainer: {
    position: 'absolute',
    width: LENGTH_FROM_CLUSTER_TO_MARKERS * 2,
    height: LENGTH_FROM_CLUSTER_TO_MARKERS * 2,
    top: 6 - LENGTH_FROM_CLUSTER_TO_MARKERS,
    left: 6 - LENGTH_FROM_CLUSTER_TO_MARKERS,
  },
  point: {
    backgroundColor: `${theme.palette.primary.main}50`,
    border: `1px solid ${theme.palette.primary.main}`,
    userSelect: 'none',
    pointerEvents: 'none',
  },
  buttonContainer: {
    '& .MuiButtonBase-root': {
      border: 'none',
      padding: theme.spacing(1),
      '&:nth-child(2)': {
        margin: `0 ${theme.spacing(4)}px`,
      },
    },
    '& .MuiToggleButton-root.Mui-selected': {
      backgroundColor: 'transparent',
      border: `1px solid ${theme.palette.primary.light1}`,
      borderRadius: 4,
      color: theme.palette.primary.main,
    },
  },
}));
