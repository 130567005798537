import React from 'react';
import Box from '@mui/material/Box';
import * as PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { EQUIPMENT_HEADERS } from '@uptime/shared/constants/account';
import List from '@uptime/shared/components/List';
import CertifiedIcon from '@uptime/shared/components/Icons/CertifiedIcon';
import DeviceIcon from '@uptime/shared/components/Icons/DeviceIcon';
import styles from '@uptime/shared/styles/TechnicianStyles';
import EmptyPlaceholder from '@uptime/shared/components/Placeholders/EmptyPlaceholder';

const Equipments = (props) => {
  const classes = styles();
  const { equipments: { hits } = { hits: [] } } = props;

  const rows = hits.map((item) => ({
    ...item,
    certified: item.isCertified ? (
      <CertifiedIcon />
    ) : (
      <Box pt={1} pb={1}>
        -
      </Box>
    ),
  }));

  const renderEquipments = isEmpty(hits) ? (
    <Box display="flex" alignItems="center" height="100%" className={classes.card}>
      <EmptyPlaceholder>
        <DeviceIcon />
      </EmptyPlaceholder>
    </Box>
  ) : (
    <Box
      boxSizing="border-box"
      position="absolute"
      width="100%"
      height="100%"
      overflow="auto"
      top={0}
      left={0}
      p={3}
      pl={{
        xs: 3,
        md: 0,
      }}
    >
      <List
        data={rows}
        headCells={EQUIPMENT_HEADERS.filter(({ id }) => id !== 'actions')}
        isOnlyDesktopView
      />
    </Box>
  );

  return (
    <Box flex="1" p={3} position="relative">
      {renderEquipments}
    </Box>
  );
};

Equipments.propTypes = {
  equipments: PropTypes.shape({
    hits: PropTypes.array,
  }).isRequired,
};

export default Equipments;
