import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

const InputSkeleton = () => (
  <Box p={2}>
    <Skeleton variant="text" width={100} />
    <Skeleton variant="text" height={50} />
  </Box>
);

export default InputSkeleton;
