import makeStyles from '@mui/styles/makeStyles';

export const flexSettings = {
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default makeStyles((theme) => ({
  button: {
    color: theme.palette.grey[700],
    padding: 0,
    '& .icon': {
      ...flexSettings,
      width: 40,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      marginRight: theme.spacing(0.25),
    },
    '& .text': {
      ...flexSettings,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    '& .rounded': {
      ...flexSettings,
      borderRadius: 4,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  default: {
    '& .icon, & .text, & .rounded': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      color: theme.palette.common.white,
    },
  },
  grey: {
    '& .icon, & .text, & .rounded': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.common.black,
    },
  },
  white: {
    '& .icon, & .text, & .rounded': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.grey[9000],
    },
  },
}));
