import { gql } from '@apollo/client';

const COLUMN_INFO = `
fragment columnInfo on ListSettingProperty {
    columnId,
    isActive,
    position,
}
`;

const SETTINGS = `
    photo {
        ...columnInfo,
    },
    assetId {
        ...columnInfo,
    },
    category {
        ...columnInfo,
    },
    make {
        ...columnInfo,
    },
    model {
        ...columnInfo,
    },
    lastDate {
        ...columnInfo,
    },
    assignedUser {
        ...columnInfo,
    },
    dueDate {
        ...columnInfo,
    },
    deviceUse {
        ...columnInfo,
    },
    facility {
        ...columnInfo,
    },
    area {
        ...columnInfo,
    },
`;

export const getAllDevices = gql`
  query fetchDevices($userId: Int!, $filter: FilterSettingsInput! = {}, $sort: SortSettingsInput) {
    devices: fetchDevices(userId: $userId, filter: $filter, sort: $sort) {
      hits {
        id
        make
        model
        category
        userId
        assetId
        facilityId
        areaId
        manufactureYear
        deviceUse
      }
    }
  }
`;

export const getDevicesV2 = gql`
  query getDevices(
    $userId: Int!
    $sort: SortSettingsInput
    $filter: FilterSettingsInput!
    $page: Int!
    $itemsAmount: Int = 10
    $searchBy: String
  ) {
    devices: fetchDevices(
      userId: $userId
      sort: $sort
      filter: $filter
      pagination: { page: $page, itemsAmount: $itemsAmount }
      searchBy: $searchBy
    ) {
      hits {
        id
        userId
        assignedUserId
        assetId
        imagePath
        fileId
        category
        make
        model
        lastDate
        assignedUser
        dueDate
        deviceUse
        facility
        area
      }
      pageCount
      currentPage
      itemsCount
    }
  }
`;

export const getDevices = gql`
  query getDevices(
    $userId: Int!
    $page: Int!
    $sort: SortSettingsInput
    $filter: FilterSettingsInput!
    $itemsAmount: Int = 10
  ) {
    devices: fetchDevices(
      userId: $userId
      pagination: { page: $page, itemsAmount: $itemsAmount }
      sort: $sort
      filter: $filter
    ) {
      hits {
        id
        userId
        assignedUserId
        assetId
        imagePath
        fileId
        category
        make
        model
        lastDate
        assignedUser
        dueDate
        deviceUse
        facility
        area
      }
      pageCount
      currentPage
      itemsCount
    }
  }
`;

export const getAssetIdList = gql`
  query fetchDevices($userId: Int!, $filter: FilterSettingsInput!, $sort: SortSettingsInput) {
    fetchDevices(userId: $userId, filter: $filter, sort: $sort) {
      hits {
        id
        assetId
        modelId: equipmentId
      }
    }
  }
`;

export const getOnly1DeviceOptions = gql`
  query getOnly1DeviceOptions($userId: Int!, $filter: FilterSettingsInput!, $sort: SortSettingsInput) {
    fetchDevices(userId: $userId, filter: $filter, sort: $sort) {
      hits {
        id
        assetId
        make
        model
      }
    }
  }
`;

export const getSettings = gql`
    ${COLUMN_INFO}
    query getSettings($userId: Int!) {
        settings: getDeviceListSetting(userId: $userId) {
            ${SETTINGS}
        }
    }
`;

export const getUserDevice = gql`
  query getUserDevice($deviceId: Int!) {
    info: getUserDevice(deviceId: $deviceId) {
      assetId
      equipment {
        model
        id
        make {
          id
          name
        }
        category {
          id
          name
        }
      }
    }
  }
`;

export const getDeviceInfo = gql`
  query getDeviceInfo($userId: Int!, $deviceId: Int!) {
    info: getDeviceInfo(userId: $userId, deviceId: $deviceId) {
      id
      userId
      imagePath
      fileId
      equipmentId
      assignedUserId
      group
      areaId
      facilityId
      assetId
      imagePath
      fileId
      riskScore
      deviceUse
      deviceStatus
      serialNo
      warrantyDate
      strategy
      purchaseYear
      purchaseCost
      manager
      phone
      email
      description
      manufactureYear
      providerId
      deviceLife
      recycleCost
      controlId
      equipment {
        model
        id
        make {
          id
          name
        }
        category {
          id
          name
        }
      }
      maintenance {
        id
        frequencyId
        lastDate
        nextDate
      }
    }
  }
`;

export const getDeviceInfoAndAdditionalData = gql`
  query getDeviceInfo($userId: Int!, $deviceId: Int!) {
    info: getDeviceInfo(userId: $userId, deviceId: $deviceId) {
      id
      userId
      imagePath
      fileId
      equipmentId
      assignedUserId
      group
      areaId
      facilityId
      assetId
      imagePath
      fileId
      riskScore
      deviceUse
      deviceStatus
      serialNo
      warrantyDate
      strategy
      purchaseYear
      purchaseCost
      manager
      phone
      email
      description
      manufactureYear
      providerId
      lastDate
      deviceLife
      recycleCost
      controlId
      uptimePercentage
      pmTotalCost
      tradeInValue
      equipment {
        id
        model
        externalId
        make {
          id
          name
        }
        category {
          id
          name
        }
      }
      maintenance {
        id
        frequencyId
        lastDate
        nextDate
      }
    }
  }
`;

export const getDeviceCostSummary = gql`
  query getDeviceCostSummary($deviceId: Int!) {
    deviceCostSummary(deviceId: $deviceId) {
      maintenanceCost
      workOrderCost
    }
  }
`;

export const getCompletedWorkOrder = gql`
  query getCompletedDeviceWorkOrder($userId: Int!, $deviceId: Int!, $page: Int!) {
    completed: getCompletedDeviceWorkOrder(userId: $userId, deviceId: $deviceId, page: $page) {
      workOrderNumber
      repairTypeName
      completedDate
      issue
      notes
      cost: finalTotalCost
    }
  }
`;

export const getIncompleteWorkOrder = gql`
  query getIncompleteDeviceWorkOrder($userId: Int!, $deviceId: Int!, $page: Int!) {
    incomplete: getIncompleteDeviceWorkOrder(userId: $userId, deviceId: $deviceId, page: $page) {
      workOrderNumber
      repairTypeName
      requestedDate: workOrderDate
      issue
      notes
      cost: totalCost
    }
  }
`;

export const getEquipmentMakers = gql`
  query getEquipmentMakers(
    $userId: Int!
    $makeIds: [Int]
    $categoryIds: [Int]
    $modelIds: [Int]
    $userSpecific: Boolean
  ) {
    makes: getEquipmentMakers(
      userId: $userId
      makeIds: $makeIds
      categoryIds: $categoryIds
      modelIds: $modelIds
      userSpecific: $userSpecific
    ) {
      value: id
      label: name
    }
  }
`;

export const getEquipmentCategories = gql`
  query getEquipmentCategories(
    $userId: Int!
    $makeIds: [Int]
    $categoryIds: [Int]
    $modelIds: [Int]
    $userSpecific: Boolean
  ) {
    categories: getEquipmentCategories(
      userId: $userId
      makeIds: $makeIds
      categoryIds: $categoryIds
      modelIds: $modelIds
      userSpecific: $userSpecific
    ) {
      value: id
      label: name
      isIot
    }
  }
`;

export const getEquipmentModels = gql`
  query getEquipmentModels(
    $userId: Int!
    $equipmentCategoryIds: [Int]
    $equipmentMakeIds: [Int]
    $modelIds: [Int]
    $userSpecific: Boolean
  ) {
    models: getEquipmentModels(
      userId: $userId
      equipmentCategoryIds: $equipmentCategoryIds
      equipmentMakeIds: $equipmentMakeIds
      modelIds: $modelIds
      userSpecific: $userSpecific
    ) {
      value: id
      label: model
      externalId
    }
  }
`;

export const getAllEquipmentCategories = gql`
  query getAllEquipmentCategories($userId: Int!) {
    categories: getAllEquipmentCategories(userId: $userId) {
      value: id
      label: name
    }
  }
`;

export const getEquipmentMakesForCategories = gql`
  query getEquipmentMakersForCategories($userId: Int, $categoryIds: [Int!]!) {
    options: getEquipmentMakersForCategories(userId: $userId, categoryIds: $categoryIds) {
      value: id
      label: name
    }
  }
`;

export const getMaintenanceFrequency = gql`
  query getMaintenanceFrequency($userId: Int!) {
    frequencies: getMaintenanceFrequency(userId: $userId) {
      value: id
      label: title
    }
  }
`;

export const getMaintenanceFrequencyAsTechnician = gql`
  query getMaintenanceFrequencyAsTechnician($facilityUserId: Int!, $signature: String!) {
    frequencies: getMaintenanceFrequencyAsTechnician(facilityUserId: $facilityUserId, signature: $signature) {
      value: id
      label: title
    }
  }
`;

export const getDeviceRunTasks = gql`
  query fetchDeviceRunTasks($userId: Int!, $deviceId: Int!, $status: RunTaskStatus!, $page: Int!) {
    runTasks: deviceRunTasks(userId: $userId, deviceId: $deviceId, status: $status, page: $page) {
      id
      status
      note
      fileUrl
      fileId
      completedBy
      task {
        name
        description
        type
        assignedUserId
        group
      }
      updatedAt
      scheduleTime
    }
  }
`;

export const getScheduleTasks = gql`
  query fetchLastDeviceCompleteRunTasks($userId: Int!, $deviceId: Int!) {
    tasks: lastDeviceCompleteRunTasks(userId: $userId, deviceId: $deviceId) {
      id
      name
      frequency
      lastCompleted
    }
  }
`;

// TODO: should be moved to file.js
export const getDeviceDocuments = gql`
  query fetchDeviceDocuments($deviceId: Int!) {
    documents: fetchDeviceDocuments(deviceId: $deviceId) {
      id
      name
      fileUrl
      file {
        id
        fileName
        mimeType
      }
    }
  }
`;

export const getDeviceFileUrl = gql`
  query fetchDeviceFileUrl($deviceId: Int!, $fileId: String!) {
    url: fetchDeviceFileUrl(deviceId: $deviceId, fileId: $fileId)
  }
`;

export const getDeviceMaintenance = gql`
  query getDeviceMaintenance($userId: Int!, $deviceId: Int!, $page: Int!) {
    workOrders: getDeviceMaintenance(userId: $userId, deviceId: $deviceId, page: $page) {
      frequency
      next
      last
    }
  }
`;

export const isAssetIdUnique = gql`
  query isAssetIdUnique($userId: Int!, $assetId: String!, $deviceId: Int, $signature: String) {
    isUnique: isAssetIdUnique(
      userId: $userId
      assetId: $assetId
      deviceId: $deviceId
      signature: $signature
    ) {
      isUnique
    }
  }
`;
export const isControlIdUnique = gql`
  query isControlIdUnique($userId: Int!, $controlId: String!, $deviceId: Int, $signature: String) {
    isUnique: isControlIdUnique(
      userId: $userId
      controlId: $controlId
      deviceId: $deviceId
      signature: $signature
    ) {
      isUnique
    }
  }
`;

// Mutations
export const onUpdateSettings = gql`
    ${COLUMN_INFO}
    mutation updateDeviceListSetting($userId: Int!, $settings: DeviceListSettingInput){
        updateDeviceListSetting(userId: $userId, settings: $settings) {
            ${SETTINGS}
        }
    }
`;

export const onCreateDevice = gql`
  mutation createDevice(
    $userId: Int!
    $deviceData: DeviceCreationInput
    $equipmentData: EquipmentModelInput
    $frequencyData: [FrequencyInput]
  ) {
    createDevice(
      userId: $userId
      deviceData: $deviceData
      equipmentData: $equipmentData
      frequencyData: $frequencyData
    ) {
      deviceId
    }
  }
`;

export const onCreateDeviceByTechnician = gql`
  mutation createDeviceByTechnician(
    $facilityUserId: Int!
    $signature: String!
    $deviceData: DeviceCreationInput
    $equipmentData: EquipmentModelInput
    $frequencyData: [FrequencyInput]
    $facilityTitle: String
  ) {
    createDeviceByTechnician(
      facilityUserId: $facilityUserId
      signature: $signature
      deviceData: $deviceData
      equipmentData: $equipmentData
      frequencyData: $frequencyData
      facilityTitle: $facilityTitle
    ) {
      deviceId
    }
  }
`;

export const onUpdateDevice = gql`
  mutation updateDevice(
    $userId: Int!
    $deviceData: DeviceUpdatingInput
    $equipmentData: EquipmentModelInput
    $frequencyData: [FrequencyInput]
  ) {
    updateDevice(
      userId: $userId
      deviceData: $deviceData
      equipmentData: $equipmentData
      frequencyData: $frequencyData
    ) {
      deviceId
    }
  }
`;

// TODO: move to file.js
export const onDeleteDeviceFile = gql`
  mutation deleteDeviceFile($deviceId: Int!, $fileId: String!) {
    deleteDeviceFile(deviceId: $deviceId, fileId: $fileId)
  }
`;

export const onDeleteFacilityFile = gql`
  mutation deleteFacilityFile($facilityId: Int!) {
    deleteFacilityFile(facilityId: $facilityId)
  }
`;

export const getFacilityEquipmentCategories = gql`
  query getFacilityEquipmentCategories($userId: Int!, $facilityIds: [Int!]!) {
    devices: getFacilitiesEquipmentCategories(userId: $userId, facilityIds: $facilityIds) {
      facilityId
      options: categories {
        value: id
        label: name
      }
    }
  }
`;

export const getCompletedTargetWorkOrder = gql`
  query getCompletedDeviceWorkOrder(
    $pagination: PaginationInput
    $sortBy: [WorkOrderSortInput!]
    $filter: WorkOrderFilterInput!
  ) {
    workOrderList(pagination: $pagination, sortBy: $sortBy, filter: $filter) {
      hits {
        processId
        publicId
        accountId
        technicianUserId
        assignedUserId
        statusAlias
        statusTitle
        statusId
        flowId
        flowAlias
        flowTitle
        strategyId
        strategyAlias
        strategyTitle
        requestedDate
        completedDate
        createdAt
        updatedAt
        flowData
        targetData
      }
    }
  }
`;

export const addDeviceToProcess = gql`
  mutation addDeviceToProcess(
    $processId: String!
    $targetDevice: DeviceTargetInput!
    $flowDevice: DeviceFlowInput!
  ) {
    addDeviceToProcess(processId: $processId, targetDevice: $targetDevice, flowDevice: $flowDevice) {
      publicId
    }
  }
`;

export const getDeviceList = gql`
  query getDeviceList(
    $searchBy: String
    $filter: DeviceFilterInput
    $sortBy: [DeviceSortInput!]
    $pagination: PaginationInput
  ) {
    devices: deviceList(searchBy: $searchBy, filter: $filter, sortBy: $sortBy, pagination: $pagination) {
      hits {
        id
        description
        assetId
        strategy
        lastDate
        dueDate
        status
        deviceUse
        fileId
        facilityId
        imagePath
        manufactureYear
        group
        createdAt
        updatedAt
        modelTitle
        makeTitle
        categoryTitle
        userId
      }
      pageCount
      currentPage
      itemsCount
    }
  }
`;

export const getDeviceDetails = gql`
  query getDeviceDetails($deviceId: Int!) {
    deviceDetails(deviceId: $deviceId) {
      id
      description
      userId
      serviceProviderId
      assignedUserId
      assetId
      facilityId
      areaId
      riskScore
      purchaseCost
      purchaseYear
      serialNo
      strategy
      lastDate
      warrantyDate
      dueDate
      managerName
      managerPhone
      managerEmail
      status
      deviceUse
      fileId
      imagePath
      manufactureYear
      group
      createdAt
      updatedAt
      modelId
      modelTitle
      categoryId
      categoryTitle
      makeId
      makeTitle
      controlId
      uptimePercentage
      deviceLife
      pmTotalCost
      recycleCost
    }
  }
`;

export const onDeviceBulkUpdate = gql`
  mutation deviceBulkUpdate($userId: Int!, $payload: BulkUpdateInput!) {
    deviceBulkUpdate(userId: $userId, payload: $payload)
  }
`;

export const getReplacementAnalysis = gql`
  query getReplacementAnalysis($deviceId: Int!) {
    getReplacementAnalysis(deviceId: $deviceId) {
      deviceAge
      tradeInValue
      serviceCost
      bookValue
      purchaseCost
      lastYearCost
      lastFiveYearsCost
      estimatedNextYear
      estimatedNextFiveYears
      recommendation
    }
  }
`;

export const deviceBulkUpdate = gql`
  mutation (
    $ids: [Int!]!
    $selection: BulkUpdateSelection!
    $field: DeviceBulkUpdateField!
    $value: String!
    $filters: JSONObject
    $searchBy: String
  ) {
    deviceBulkUpdate(
      ids: $ids
      selection: $selection
      field: $field
      value: $value
      filters: $filters
      searchBy: $searchBy
    )
  }
`;

// TODO: move to gocheckit.js
export const syncDeviceSchedule = gql`
  mutation syncDeviceSchedule(
    $deviceId: Int!
    $userId: Int!
    $oldFacilityId: Int!
    $newFacilityId: Int!
    $schedule: ScheduleInput
    $nonWorkingDays: [NonWorkingDay]
    $timezone: String
  ) {
    syncDeviceSchedule(
      deviceId: $deviceId
      userId: $userId
      oldFacilityId: $oldFacilityId
      newFacilityId: $newFacilityId
      schedule: $schedule
      nonWorkingDays: $nonWorkingDays
      timezone: $timezone
    )
  }
`;
