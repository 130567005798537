import React, { useEffect, useState } from 'react';
import { Field, FormSpy } from 'react-final-form';
import * as PropTypes from 'prop-types';
import { usePrevious } from '@uptime/shared/hooks';

import {
  getLocationStateAndPostal,
  getLocationById,
  doFetchAddressOptions,
} from '@uptime/shared/utils/facilities';
import Input from '@uptime/shared/components/Input';

const SearchAddress = (props) => {
  const { onSelectAddress, label, name, placeholder, isDisabled, params, testid } = props;
  const [searchText, setSearchText] = useState('');
  const previousSearchText = usePrevious(searchText);
  const [addressOptions, setAddressOptions] = useState([]);

  useEffect(() => {
    if (previousSearchText === searchText) {
      return;
    }
    !searchText && setAddressOptions([]);
    if (searchText && !isDisabled) {
      doFetchAddressOptions(searchText, setAddressOptions, params);
    }
  }, [isDisabled, searchText, params, previousSearchText]);

  const setAddressOne = (addressId) => {
    const location = getLocationById(addressOptions, addressId);

    const { postal, state, city } = getLocationStateAndPostal(location.context);
    const [longitude, latitude] = location.center;

    const viewport = { latitude, longitude, zoom: 11 };
    onSelectAddress({ label: location.label, postal, state, city }, viewport);
  };

  return (
    <FormSpy subscription={{ values: true, modified: true }}>
      {({ values }) => (
        <Field
          autoComplete="off"
          inputProps={{ autofill: 'off' }}
          placeholder={placeholder}
          onKeyUp={() => setSearchText(values[name])}
          options={addressOptions}
          onOptionClick={(addressId) => setAddressOne(addressId)}
          name={name}
          label={label}
          component={Input}
          disabled={isDisabled}
          testid={testid}
        />
      )}
    </FormSpy>
  );
};

SearchAddress.propTypes = {
  onSelectAddress: PropTypes.func.isRequired,
  params: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default SearchAddress;
