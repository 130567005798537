import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography/Typography';

import NoResultsIcon from '../../../components/Icons/NoResultsIcon';

export default () => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Box pt={6} pb={4}>
      <NoResultsIcon />
    </Box>
    <Typography variant="h3">No Results Found</Typography>
    <Box pt={2}>
      <Typography variant="caption">
        No technician were found. Please change filters and try again.
      </Typography>
    </Box>
  </Box>
);
