import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => ({
  smallAvatar: {
    width: '36px !important',
    height: '36px !important',
    transform: 'none',
  },
  button: {
    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.5)',
  },
  icon: {
    textShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
  },
}));
