import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

import { ALL_WEEK_DAYS } from '@uptime/shared/constants';

export default () => (
  <Box p={3} pt={1}>
    {ALL_WEEK_DAYS.map(({ short }) => (
      <Box pb={0.5}>
        <Skeleton key={short} variant="rectangular" height={100} width="100%" style={{ borderRadius: 6 }} />
      </Box>
    ))}
  </Box>
);
