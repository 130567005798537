import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

import Page from '@uptime/shared/components/Page';
import FiltersSkeleton from '@uptime/shared/screens/Marketplace/partials/FiltersSkeleton';
import TableSkeleton from '@uptime/shared/screens/Marketplace/partials/TableSkeleton';
import styles from '@uptime/shared/styles/MarketplaceStyles';

const FirstLoading = () => {
  const classes = styles();

  return (
    <Box className={classes.root}>
      <Page
        title={<Skeleton variant="text" width={100} height={36} />}
        additional={
          <Box pr={2}>
            <Skeleton variant="text" width={100} height={36} />
          </Box>
        }
        filters={<Skeleton variant="text" width={100} height={60} />}
      >
        <FiltersSkeleton />
        <Box pr={2} pl={2}>
          <TableSkeleton />
        </Box>
      </Page>
    </Box>
  );
};

export default FirstLoading;
