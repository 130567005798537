import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';
import max from 'lodash/max';
import min from 'lodash/min';
import flow from 'lodash/flow';
import turf from 'turf';
import uniqBy from 'lodash/uniqBy';
import cond from 'lodash/cond';
import stubTrue from 'lodash/stubTrue';

import {
  CIRCLE_ANGLE,
  LENGTH_FROM_CLUSTER_TO_MARKERS,
  METERS_IN_MILE,
  METERS_IN_PIXEL_FOR_O_ZOOM,
  LONGITUDE_INDEX,
  LATITUDE_INDEX,
} from '@uptime/shared/constants';

export const milesToPixelsAtZoom = (miles = 0, latitude = 0, zoom = 0) =>
  (miles * METERS_IN_MILE) / (METERS_IN_PIXEL_FOR_O_ZOOM / 2 ** zoom) / Math.cos((latitude * Math.PI) / 180);

export const comparePropsForItemsOnMap = (prevProps, nextProps) => {
  const { items: prevItems, center: prevCenter } = prevProps || {};
  const { items: nextItems, center: nextCenter } = nextProps || {};
  const isSameCenter = isEqual(prevCenter, nextCenter);

  if (isArray(prevItems) && isArray(nextItems)) {
    const isNotChangedItems = prevItems.every((prevItem) => {
      const itInNextItems = nextItems.find(({ id }) => prevItem.id === id);

      if (!itInNextItems) return false;

      const nextItem = nextItems.find(({ id }) => prevItem.id === id);

      const prev = omit(prevItem, ['photo', 'actions']);
      const next = omit(nextItem, ['photo', 'actions']);

      return isEqual(prev, next);
    });

    return isNotChangedItems && prevItems.length === nextItems.length && isSameCenter;
  }

  return prevItems === nextItems && isSameCenter;
};

export const comparePropsForMarker = (prevProps, nextProps) => {
  const { item: prevItem = {} } = prevProps || {};
  const { item: nextItem = {} } = nextProps || {};

  const prev = omit(prevItem, ['photo', 'actions']);
  const next = omit(nextItem, ['photo', 'actions']);

  return isEqual(prev, next);
};

export const detectZoom = (pixels, latitude, diameterInMiles) => {
  if (!pixels || !diameterInMiles) return 0;

  const length = METERS_IN_PIXEL_FOR_O_ZOOM * pixels;
  const zoom = Math.log2((length * Math.cos((latitude * Math.PI) / 180)) / diameterInMiles);

  return Math.trunc(zoom);
};

export const getPartialPositions = (items = [], coordinateType = 'latitude') => {
  const coordinatesCollection = (items) => items.map((coordinate) => coordinate[coordinateType]);

  const getMax = (items) => max(items);
  const getMin = (items) => min(items);

  const curried = (fns) => flow([...fns]);

  const minCurried = curried([coordinatesCollection, getMin]);
  const maxCurried = curried([coordinatesCollection, getMax]);

  const minCoordinate = minCurried(items);
  const maxCoordinate = maxCurried(items);

  return {
    maxCoordinate,
    minCoordinate,
  };
};

export const getLimitCoordinates = (items) => {
  const { maxCoordinate: maxLatitude, minCoordinate: minLatitude } = getPartialPositions(items);
  const { maxCoordinate: maxLongitude, minCoordinate: minLongitude } = getPartialPositions(
    items,
    'longitude'
  );

  return {
    maxLatitude,
    maxLongitude,
    minLatitude,
    minLongitude,
  };
};

export const getCirclesCoordinates = (items = []) =>
  items.reduce((accumulator, { longitude, latitude, distance }) => {
    const maxPoint = turf.point([longitude, latitude]);
    const {
      geometry: {
        coordinates: [coordinates],
      },
    } = turf.buffer(maxPoint, distance, 'miles');

    return [...accumulator, ...coordinates];
  }, []);

export const getLimitCoordinatesIncludedDistance = (items) => {
  const circles = getCirclesCoordinates(items);

  const { maxCoordinate: maxCircleLongitude, minCoordinate: minCircleLongitude } = getPartialPositions(
    circles,
    LONGITUDE_INDEX
  );
  const { maxCoordinate: maxCircleLatitude, minCoordinate: minCircleLatitude } = getPartialPositions(
    circles,
    LATITUDE_INDEX
  );

  return {
    maxLatitude: maxCircleLatitude,
    maxLongitude: maxCircleLongitude,
    minLatitude: minCircleLatitude,
    minLongitude: minCircleLongitude,
  };
};

export const getCoordinatesIncludedDistance = cond([
  [(items = []) => items[0] && items[0].distance, getLimitCoordinatesIncludedDistance],
  [stubTrue, getLimitCoordinates],
]);

export const getMapParams = (items = [], mapRef = {}) => {
  const { current } = mapRef;

  if (!current || items.length === 0) return {};

  const {
    _container: {
      current: { clientHeight, clientWidth },
    },
  } = current;
  const { maxLatitude, minLatitude, maxLongitude, minLongitude } = getCoordinatesIncludedDistance(items);

  const heightInMiles = turf.distance([minLongitude, maxLatitude], [minLongitude, minLatitude], 'meters');
  const widthInMiles = turf.distance([maxLongitude, maxLatitude], [minLongitude, maxLatitude], 'meters');

  if (items.length === 1 || (heightInMiles === 0 && widthInMiles === 0)) {
    const { latitude, longitude } = items[0];

    return {
      zoom: 10,
      center: {
        latitude,
        longitude,
      },
    };
  }

  const diameterInMiles = widthInMiles > heightInMiles ? widthInMiles : heightInMiles;
  const pixels = clientWidth < clientHeight ? clientWidth : clientHeight;

  const zoom = detectZoom(pixels, minLatitude, diameterInMiles);
  const center = {
    latitude: (maxLatitude + minLatitude) / 2,
    longitude: (maxLongitude + minLongitude) / 2,
  };

  return {
    center,
    zoom,
  };
};

export const getAngle = (pointCount) => (pointCount ? CIRCLE_ANGLE / pointCount : 0);

export const getRadians = (angle = 0) => angle * (Math.PI / (CIRCLE_ANGLE / 2));

export const getCoordinatesOnCircle = (positionNumber = 0, angle = 0) => {
  const angleInRadians = getRadians(positionNumber * angle);

  const top = LENGTH_FROM_CLUSTER_TO_MARKERS * Math.cos(angleInRadians);
  const left = LENGTH_FROM_CLUSTER_TO_MARKERS * Math.sin(angleInRadians);

  return {
    top: Math.round(top),
    left: Math.round(left),
  };
};

export const getMarkersFromCluster = (cluster, marker) => {
  try {
    const accumulatedItems = isArray(cluster.items) ? cluster.items : cluster.props.children;
    const otherClusterItems = isArray(marker.items) ? marker.items : [];
    const markerInside = marker.props.children;
    const items = [...accumulatedItems, ...otherClusterItems, ...markerInside];

    return uniqBy(
      items,
      ({
        props: {
          item: { id },
        },
      }) => id
    );
  } catch {
    return [];
  }
};
